import React from 'react';
import PropTypes from 'prop-types';
import Select from '@components/layout/Select';
import Radio from '@components/layout/Radio';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelRegistrationModal from '@components/layout/CancelRegistrationModal';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { checkIfConditionIsFulfilled } from '../../../../helpers';

const queryString = require('query-string');

export default class InfoFields extends React.Component {
  constructor(props) {
    super(props);
    this.query = queryString.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    this.userIsRegistered = false;
    this.state = {
      userFields: {},
      requiredFields: [],
      showPassword: false,
      errors: [],
      cancelConfirmation: false,
    };
    this.closeModal = this.closeModal.bind(this);
    this.checkIfConditionIsFulfilled =
      this.checkIfConditionIsFulfilled.bind(this);
  }

  closeModal(result = false) {
    this.setState(() => ({ cancelConfirmation: false }));
    if (result) {
      this.props.changeSection('generalInfo');
      window.location.reload();
    }
  }

  componentDidMount() {
    if (JSON.stringify(this.props.userFields) !== '{}') {
      this.setState({
        userFields: {
          ...this.props.userFields,
        },
      });
    } else {
      this.setState({ userFields: this.props.knownUserFields });
    }
    const requiredFields = [...this.state.requiredFields];
    if (this.props.user) {
      this.userIsRegistered = this.props.user.data.active;
    }

    {
      this.props.policy &&
        this.props.policy.fields.map((field) => {
          if (field.required === 1) {
            requiredFields.push(field.key);
          }
        });
      this.setState({ requiredFields });
    }
  }
  checkIfConditionIsFulfilled(field, userFields = this.state.userFields) {
    return checkIfConditionIsFulfilled(field, userFields);
  }

  parseAndDisplayField(f) {
    const conditionFulfilled = this.checkIfConditionIsFulfilled(f);

    if (!conditionFulfilled) {
      if (
        this.state.userFields[f.key] != undefined &&
        this.state.userFields[f.key] != ''
      )
        this.setState((prevState) => {
          const userFields = Object.assign({}, prevState.userFields);
          delete userFields[f.key];
          return {
            userFields,
          };
        });
      return null;
    }

    switch (f.type) {
      case 'dropdown': {
        const options = {};
        if (typeof f.value === 'string') {
          f['value'] = JSON.parse(f.value);
        }
        f.value.map((v) => (options[v] = v));
        if (f.askUser || f.used || f.required) {
          return (
            <div
              className={`field form-group ${
                this.state.errors.includes(f.key) ? 'has-error' : ''
              }`}
              style={{
                marginBottom: this.state.errors.includes(f.key) ? '42px' : '',
              }}
              key={f.key}>
              <label
                className={`form-group ${
                  this.state.errors.includes(f.key) ? 'has-error' : ''
                }`}>
                {f.name} {f.required ? '*' : ''}
              </label>
              <Select
                options={options}
                placeholder="-- Select --"
                value={this.state.userFields[f.key]}
                onChange={(e) =>
                  this.changeFields({ target: { value: e } }, f.key)
                }
              />
              <span
                style={{
                  display: this.state.errors.includes(f.key) ? 'flex' : 'none',
                }}
                className="required-field-message">
                {window.lang[this.props.language]['requiredField']}
              </span>
              <span
                style={{
                  visibility: this.state.errors.includes(f.key)
                    ? 'visible'
                    : 'hidden',
                }}
                className="error-icon icon-wrapper">
                <ErrorOutlineIcon />
              </span>
            </div>
          );
        } else {
          return null;
        }
      }
      case 'yes_no': {
        let val = this.state.userFields[f.key];
        if (val === undefined) {
          val = '';
        } else {
          val = val == 1 || val == 'yes' ? 'yes' : 'no';
        }
        if (f.askUser || f.used || f.required) {
          return (
            <div
              className={`field form-group ${
                this.state.errors.includes(f.key) ? 'has-error' : ''
              }`}
              style={{
                marginBottom: this.state.errors.includes(f.key) ? '42px' : '',
              }}
              key={f.key}>
              <label
                className={`form-group ${
                  this.state.errors.includes(f.key) ? 'has-error' : ''
                }`}>
                {`${f.name}${f.required ? ' *' : ''}`}
              </label>
              <div className="kmb-radio">
                <Radio
                  id={'radio-input-' + f.key}
                  checked={val}
                  onChange={(e) =>
                    this.changeFields(
                      { target: { value: e === 'yes' ? 1 : 0 } },
                      f.key
                    )
                  }
                />
              </div>
              <span
                style={{
                  display: this.state.errors.includes(f.key) ? 'flex' : 'none',
                }}
                className="required-field-message">
                {window.lang[this.props.language]['requiredField']}
              </span>
              <span
                style={{
                  visibility: this.state.errors.includes(f.key)
                    ? 'visible'
                    : 'hidden',
                }}
                className="error-icon icon-wrapper">
                <ErrorOutlineIcon />
              </span>
            </div>
          );
        } else {
          return null;
        }
      }
      case 'textarea': {
        if (f.askUser || f.used || f.required) {
          return (
            <div
              className={`field form-group ${
                this.state.errors.includes(f.key) ? 'has-error' : ''
              }`}
              style={{
                marginBottom: this.state.errors.includes(f.key) ? '42px' : '',
              }}
              key={f.key}>
              <label
                className={`form-group ${
                  this.state.errors.includes(f.key) ? 'has-error' : ''
                }`}>
                {`${f.name}${f.required ? ' *' : ''}`}
              </label>
              <textarea
                className="form-control text-area"
                value={this.state.userFields[f.key]}
                rows="5"
                onChange={(e) => this.changeFields(e, f.key)}
                placeholder={`${f.name}${f.required ? ' *' : ''}`}
              />
              <span
                style={{
                  display: this.state.errors.includes(f.key) ? 'flex' : 'none',
                }}
                className="required-field-message text-area-required-message">
                {window.lang[this.props.language]['requiredField']}
              </span>
              <span
                style={{
                  visibility: this.state.errors.includes(f.key)
                    ? 'visible'
                    : 'hidden',
                }}
                className="error-icon icon-wrapper">
                <ErrorOutlineIcon />
              </span>
            </div>
          );
        } else {
          return null;
        }
      }
      case 'section': {
        return <h3 className="section-wrapper">{f.name}</h3>;
      }
      default:
        if (f.askUser || f.used || f.required) {
          return (
            <div
              className={`field form-group ${
                this.state.errors.includes(f.key) ? 'has-error' : ''
              }`}
              key={f.key}
              style={{
                marginBottom: this.state.errors.includes(f.key) ? '42px' : '',
              }}>
              <label
                className={`form-group ${
                  this.state.errors.includes(f.key) ? 'has-error' : ''
                }`}>
                {f.name} {f.required ? '*' : ''}
              </label>
              <input
                value={this.state.userFields[f.key]}
                disabled={f.key === 'email' ? true : false}
                onChange={(e) => this.changeFields(e, f.key)}
                name={f.key}
                type={
                  f.key === 'password' && !this.state.showPassword
                    ? 'password'
                    : 'text'
                }
                className={`field-input${
                  this.state.errors.includes(f.key) ? ' field-error' : ''
                }${f.key === 'email' ? ' field-input-disabled' : ''}`}
              />

              <span
                style={{
                  display: this.state.errors.includes(f.key) ? 'flex' : 'none',
                }}
                className="required-field-message">
                {f.key === 'password'
                  ? this.state.userFields.password === ''
                    ? window.lang[this.props.language]['requiredField']
                    : this.state.passwordStrength
                  : window.lang[this.props.language]['requiredField']}
              </span>
              <span
                style={{
                  visibility: this.state.errors.includes(f.key)
                    ? 'visible'
                    : 'hidden',
                }}
                className="error-icon icon-wrapper">
                <ErrorOutlineIcon />
              </span>

              {!this.state.errors.includes(f.key) && f.key === 'password' && (
                <span
                  className="icon-wrapper"
                  onClick={() =>
                    this.setState({ showPassword: !this.state.showPassword })
                  }
                  style={{
                    color: window.colorPalette.primary[500],
                    cursor: 'pointer',
                  }}>
                  {this.state.showPassword ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityIcon />
                  )}
                </span>
              )}
            </div>
          );
        } else {
          return null;
        }
    }
  }
  checkPasswordStrength(password) {
    let errorMessage = '';
    if (password.length < 6) {
      errorMessage = 'Password must be at least 6 characters long';
    }
    if (!password.match(/[A-Z]/)) {
      errorMessage = 'Password must contain at least one uppercase letter';
    }
    if (!password.match(/[0-9]/)) {
      errorMessage = 'Password must contain at least one number';
    }
    return errorMessage;
  }
  changeFields(e, key) {
    let passwordStrength = '';
    if (e.target.name === 'password') {
      passwordStrength = this.checkPasswordStrength(e.target.value);
    }
    this.setState((prevState) => {
      const userFields = Object.assign({}, prevState.userFields);
      userFields[key] = e.target.value;
      // check for changes in required fields
      const requiredFields = [];
      this.props?.policy?.fields?.forEach?.((field) => {
        const conditionIsFulfilled = this.checkIfConditionIsFulfilled(
          field,
          userFields
        );
        if (!conditionIsFulfilled) return;
        if (field.required === 1) requiredFields.push(field.key);
      });
      const errors =
        e.target.value != null
          ? [...this.state.errors].filter((err) => key !== err)
          : this.state.errors.filter((errKey) =>
              requiredFields.includes(errKey)
            );
      return {
        requiredFields,
        userFields,
        errors,
        passwordStrength,
      };
    });
  }

  render() {
    return (
      <>
        <input
          type="password"
          name="password"
          id="realPassword"
          style={{
            marginLeft: '-1000000px',
            position: 'absolute',
            zIndex: -200,
          }}
        />
        <div
          className={`general-info-text ${
            this.props.policy.registrationInfo ? 'full-margin' : ''
          }`}>
          {window.lang[this.props.language]['exclusiveInformation']}
        </div>
        {this.props.policy.registrationInfo ? (
          <div className="login-info-text">
            {this.props.policy.registrationInfo}
          </div>
        ) : null}
        {this.props.policy &&
          (() => {
            const processedFields = this.props.policy.fields
              .map((field) => this.parseAndDisplayField(field))
              .reduce((prev, next) => {
                // Skip adding 'prev' if the last element in 'prev' is 'h3' and 'next' is also 'h3'
                if (
                  prev.length > 0 &&
                  prev[prev.length - 1].type === 'h3' &&
                  next?.type === 'h3'
                ) {
                  prev[prev.length - 1] = next;
                  return prev;
                }

                // Add 'next' unless it's undefined
                if (next) {
                  return [...prev, next];
                }

                return prev;
              }, []);

            // Remove the last element if it's of type 'h3'
            if (
              processedFields.length > 0 &&
              processedFields[processedFields.length - 1].type === 'h3'
            ) {
              return processedFields.slice(0, -1);
            }

            return processedFields;
          })()}
        <div className="info">
          {window.lang[this.props.language]['requiredFields']}
        </div>
        <div className="btn-container flex-end">
          <div
            className={`fill-in-fields-mobile ${
              this.state.errors.length > 0
                ? 'fill-in-fields-mobile-visible'
                : ''
            }`}>
            {window.lang[this.props.language].pleaseFillRequiredFields}
          </div>
          <button
            className="step-btn single row-reverse"
            style={{ backgroundColor: window.colorPalette.primary[600] }}
            onClick={() => {
              const errors = this.props.policy.fields
                .map((field) => {
                  if (
                    field.required &&
                    (this.state.userFields[field.key] == undefined ||
                      this.state.userFields[field.key] === '') &&
                    this.checkIfConditionIsFulfilled(field)
                  ) {
                    return field.key;
                  }
                  return false;
                })
                .filter((v) => v !== false);

              if (
                this.state.userFields.password &&
                this.checkPasswordStrength(this.state.userFields.password)
              ) {
                errors.push('password');
              }

              if (errors.length > 0) {
                return this.setState({ errors });
              }
              if (!this.userIsSponsored && !this.userIsRegistered) {
                this.props.handleNext();
                this.props.calculateSubscription(
                  this.state.userFields,
                  this.query.t
                );
              }
              this.props.setUserFields(this.state.userFields);
            }}>
            <div className="btn-next-wrapper">
              <div className="btn-txt-container">
                <div className="btn-txt">
                  <span className="txt-mobile">
                    {window.lang[this.props.language].next}
                  </span>
                  <span className="txt-1">
                    {window.lang[this.props.language].nextStep}:
                  </span>
                  <br />
                  <span className="txt-2">{this.props.nextStep}</span>
                </div>
              </div>
              <div className="arrow-container btn-next">
                <ArrowForwardIcon />
              </div>
            </div>
          </button>
          <div className="bottom-info">
            <div
              onClick={() => {
                this.setState({ cancelConfirmation: true });
              }}
              style={{
                color: window.colorPalette.primary[600],
                textDecorationColor: window.colorPalette.primary[600],
              }}
              className={`cancel-registration`}>
              {window.lang[this.props.language].cancelRegistration}
            </div>
            <div
              className={`fill-in-fields ${
                this.state.errors.length > 0 ? 'visible-fill-in-fields' : ''
              }`}>
              {window.lang[this.props.language].pleaseFillRequiredFields}
            </div>
          </div>
        </div>
        {this.state.cancelConfirmation && (
          <CancelRegistrationModal
            language={this.props.language}
            setModalVisibility={() =>
              this.setState({ cancelConfirmation: false })
            }
          />
        )}
      </>
    );
  }
}

InfoFields.propTypes = {
  prevStep: PropTypes.string,
  nextStep: PropTypes.string,
  language: PropTypes.string,
  policy: PropTypes.object,
  user: PropTypes.object,
  calculateSubscription: PropTypes.func,
  changeSection: PropTypes.func.isRequired,
  setAvailableProducts: PropTypes.func,
  knownUserFields: PropTypes.object,
  setUserFields: PropTypes.func,
  setSubscription: PropTypes.func,
  userFields: PropTypes.object,
  registerUser: PropTypes.func,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
};
