import { api } from './ajax';
import {
  go404,
  buildTokenName,
  isEmpty,
  buildSessionsCalendar,
  checkMeta,
} from '../helpers';
import { getHistory } from '@helpers/history';
import moment from 'moment';
import queryString from 'query-string';

export const SET_URL = 'SET_URL';
export const SET_PRODUCTS_TAB_INDEX = 'SET_PRODUCTS_TAB_INDEX';
export const SETUP_DATA = 'SETUP_DATA';
export const CHANGE_SCREEN = 'CHANGE_SCREEN';
export const SET_COUNTER = 'SET_COUNTER';
export const TOGGLE_PASSWORD_VISIBILITY = 'TOGGLE_PASSWORD_VISIBILITY';
export const RECEIVED_USER_GET = 'RECEIVED_USER_GET';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const NOT_LOGGED_IN = 'NOT_LOGGED_IN';
export const USER_NOT_LOGGED_IN = 'USER_NOT_LOGGED_IN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SETUP_MENU = 'SETUP_MENU';
export const CHANGE_SECTION = 'CHANGE_SECTION';
export const SHOW_PRODUCT = 'SHOW_PRODUCT';
export const SHOW_SPEECHES = 'SHOW_SPEECHES';
export const SHOW_SPONSOR = 'SHOW_SPONSOR';
export const BUILD_SESSIONS_CALENDAR = 'BUILD_SESSIONS_CALENDAR';
export const SHOW_SPEAKER = 'SHOW_SPEAKER';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';
export const REMOVING_NOTIFICATION = 'REMOVING_NOTIFICATION';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const SHOW_HOTEL = 'SHOW_HOTEL';
export const SHOW_VIDEO = 'SHOW_VIDEO';
export const SEARCH_VIDEO = 'SEARCH_VIDEO';
export const SHOW_ABSTRACT = 'SHOW_ABSTRACT';
export const SHOW_ABSTRACTS = 'SHOW_ABSTRACTS';
export const PREVIEW_ABSTRACT = 'PREVIEW_ABSTRACT';
export const QUESTIONNAIRE_FAILED = 'QUESTIONNAIRE_FAILED';
export const HAS_UNSAVED_CHANGES = 'HAS_UNSAVED_CHANGES';
export const HAS_REQUESTED_CHANGE_SECTION = 'HAS_REQUESTED_CHANGE_SECTION';
export const TOGGLE_MOBILE = 'TOGGLE_MOBILE';
export const SHOW_PAYMENT = 'SHOW_PAYMENT';
export const RECEIVED_USER_UPDATE = 'RECEIVED_USER_UPDATE';
export const RECEIVED_PRODUCTS_LIST = 'RECEIVED_PRODUCTS_LIST';
export const RECEIVED_EXHIBITION_LIST = 'RECEIVED_EXHIBITION_LIST';
export const SHOW_EXHIBITION = 'SHOW_EXHIBITION';
export const RECEIVED_EXHIBITION_GET = 'RECEIVED_EXHIBITION_GET';
export const RECEIVED_POLICIES_GET = 'RECEIVED_POLICIES_GET';
export const UPDATE_IN_VIEW_MENU_ITEM = 'UPDATE_IN_VIEW_MENU_ITEM';
export const PROMPT_LOGOUT = 'PROMPT_LOGOUT';
export const SHOW_MY_ABSTRACTS = 'SHOW_MY_ABSTRACTS';
export const SHOW_PUBLISHED_ABSTRACTS = 'SHOW_PUBLISHED_ABSTRACTS';
export const SET_CAPTCHA_TOKEN = 'SET_CAPTCHA_TOKEN';
export const CAPTCHA_FAIL_COUNTER = 'CAPTCHA_FAIL_COUNTER';
export const RESET_FAIL_COUNTER = 'RESET_FAIL_COUNTER';
export const NOTE_LAST_PROHIBITED_URL = 'NOTE_LAST_PROHIBITED_URL';
export const RECEIVED_BADGE_GET_ERROR = 'RECEIVED_BADGE_GET_ERROR';
export const SET_COOLDOWN = 'SET_COOLDOWN';
export const REDUCE_COOLDOWN = 'REDUCE_COOLDOWN';
export const CLEAR_COOLDOWN = 'CLEAR_COOLDOWN';

const notificationTimeout = 7000;

export const promptLogout = () => {
  return (dispatch, getState) => {
    const state = getState();
    const tokenToErase = buildTokenName(
      state.api.policy.data.eventId,
      state.api.policy.data.id
    );
    localStorage.removeItem(tokenToErase);

    return dispatch({
      type: PROMPT_LOGOUT,
    });
  };
};

export const notificationsRead = () => (dispatch) =>
  dispatch(
    api({
      endpoint: 'user',
      action: 'notificationsRead',
      body: { data: {} },
    })
  );

export const getUserNotifications = () => (dispatch) =>
  dispatch(
    api({
      endpoint: 'user',
      action: 'notifications',
    })
  );

export const toggleMobile = () => ({
  type: TOGGLE_MOBILE,
});

export const submitQuestionnaire = (
  fields,
  token,
  endpoint = 'questionnaire',
  action = 'submit'
) => {
  return (dispatch, getState) => {
    const state = getState();
    const policyId = state.api.policy.data.id;
    const eventId = state.api.policy.data.eventId;
    const info = {};
    for (const [k, f] of Object.entries(fields)) {
      info[k] = fields[k];
      if ((info[k].value || '').length === 0) {
        delete info[k];
        continue;
      }
      info[k] =
        info[k].type === 'yes_no' ? f.value === 'yes' || f.value == 1 : f.value;
    }

    const request = {
      endpoint,
      action,
      params: {
        eventId,
        policyId,
      },
      body: { data: { info, token } },
      insecure: true,
    };
    return dispatch(api(request))
      .then(() => {
        dispatch(resetFailCounter('questionnaire'));
        dispatch(addNotification(`Questionnaire completed successfully!`));
        return true;
      })
      .catch((err) => {
        console.error(err);
        dispatch(captchaFailCounter('questionnaire'));
        dispatch({
          type: QUESTIONNAIRE_FAILED,
          message: err.message || err,
        });
        return false;
      });
  };
};
export const getAbstract = (eventId, abstractId, meta) => (dispatch) => {
  const request = {
    endpoint: 'abstracts',
    action: 'get',
    params: { eventId, abstractId },
    query: `?${Object.entries(meta || {})
      .map(([key, value]) => `${key}=${value}`)
      .join('&')}`,
  };
  return dispatch(api(request));
};

export const getReceipt =
  (eventId, paymentId, paymentRef, token) => (dispatch) => {
    let request;
    if (paymentId) {
      request = {
        endpoint: 'payments',
        action: 'receipt',
        params: { eventId, paymentId },
        dataType: 'buffer',
      };
    } else {
      request = {
        endpoint: 'payments',
        action: 'multipleReceipt',
        params: { eventId },
        dataType: 'buffer',
        insecure: true,
      };

      request.query = `?paymentRef=${paymentRef}&tk=${token}`;
    }

    return dispatch(api(request));
  };

export const getSubscriptions = (eventId) => (dispatch) => {
  const request = {
    endpoint: 'subscriptions',
    action: 'list',
    params: { eventId },
  };

  // request.query = `?policyId=${policyId}`;
  return dispatch(api(request));
};

export const updateUser = (eventId, policyId, userId, data) => (dispatch) => {
  const request = {
    endpoint: 'user',
    action: 'update',
    params: { eventId, policyId, userId },
    body: { data },
  };

  return dispatch(api(request)).then(() => {
    dispatch(addNotification(`User updated successfully!`));
  });
};

export const showPayment = (products, info) => (dispatch, getState) => {
  const state = getState();
  const history = getHistory();
  history.push(`/account/billing`);
  if (state.menu.active !== 'billing') {
    dispatch(changeSection('billing', true));
  }
  return dispatch({
    type: SHOW_PAYMENT,
    products,
    info: info,
  });
};

export const submitPayment =
  (
    eventId,
    products,
    orderAmount,
    currencyIso,
    fields,
    userFields = {},
    signup = false,
    paymentMethod = 'card',
    orgCrmUserId
  ) =>
  (dispatch, getState) => {
    const state = getState();
    const policyId = state.api.policy.data.id;

    const request = {
      endpoint: 'abecommerce',
      action: 'getData',
      params: { eventId },
      body: {
        data: {
          policyId,
          paymentMethod,
          signup,
          products,
          orderAmount,
          currencyIso,
          ...fields,
          userFields,
          orgCrmUserId: orgCrmUserId,
        },
      },
      insecure: signup,
    };
    if (!request.body.data.orgCrmUserId) {
      delete request.body.data['orgCrmUserId'];
    }
    return dispatch(api(request));
  };

export const createNewAbstract = () => (dispatch, getState) => {
  const state = getState();

  const history = getHistory();
  history.push(`/abstracts/create`);

  if (state.menu.active !== 'abstractManagement') {
    dispatch(changeSection('abstractManagement', true));
  }

  dispatch({
    type: SHOW_ABSTRACT,
  });
};

export const showAbstract = (abstractId) => (dispatch, getState) => {
  const state = getState();

  const history = getHistory();
  history.push(`/abstracts/view/${abstractId}`);

  if (state.menu.active !== 'abstractManagement') {
    dispatch(changeSection('abstractManagement', true));
  }

  dispatch({
    type: SHOW_ABSTRACT,
    abstractId,
  });
};

export const showAbstracts = () => (dispatch, getState) => {
  const state = getState();

  const history = getHistory();
  history.push(`/abstracts`);

  if (state.menu.active !== 'abstractManagement') {
    dispatch(changeSection('abstractManagement', true));
  }

  dispatch({
    type: SHOW_ABSTRACTS,
  });
};

export const showAbstractPreview = (abstractId) => (dispatch, getState) => {
  const state = getState();

  const history = getHistory();
  history.push(`/abstracts/preview/${abstractId}`);

  if (state.menu.active !== 'abstractManagement') {
    dispatch(changeSection('abstractManagement', true));
  }

  dispatch({
    type: PREVIEW_ABSTRACT,
    abstractId,
  });
};

export const showVideo =
  (sessionId, speechId = '0') =>
  (dispatch, getState) => {
    const state = getState();

    const history = getHistory();
    history.push(`/videos/view/${sessionId}${speechId ? `-${speechId}` : ''}`);

    if (state.menu.active !== 'videos') {
      dispatch(changeSection('videos', true));
    }

    return dispatch({
      type: SHOW_VIDEO,
      sessionId,
      speechId,
    });
  };

export const showLogin = () => (dispatch, getState) => {
  const state = getState();

  const history = getHistory();
  history.push(`/login`);

  if (state.menu.active !== 'videos') {
    dispatch(changeSection('login', true));
  }
};
export const showRegister = () => () => {
  const history = getHistory();
  history.push(`/register`);
};
export const searchVideo = (search, roomIds, speakerIds) => (dispatch) => {
  dispatch({
    type: SEARCH_VIDEO,
    search,
    roomIds,
    speakerIds,
  });

  dispatch(
    loadSessions({
      order: 'ASC',
      orderBy: 'startDate',
      rpp: '-1',
      search,
      roomIds,
      speakerIds,
    })
  );
};
export const getRooms = (eventId) => (dispatch) => {
  const request = {
    endpoint: 'rooms',
    action: 'list',
    params: { eventId },
  };

  return dispatch(api(request));
};
export const getAbstracts =
  (eventId, meta = {}) =>
  (dispatch) => {
    const request = {
      endpoint: 'abstracts',
      action: 'list',
      params: { eventId },
      query: '?orderBy=updatedAt',
    };

    if (!isEmpty(meta)) {
      let index = 0;
      request.query = '';
      Object.entries(meta).map(([k, v]) => {
        request.query += `${index === 0 ? '?' : '&'}${k}=${v}`;
        index++;
      });
    }
    return dispatch(api(request));
  };
export const getMyAbstracts =
  (eventId, meta = {}) =>
  (dispatch) => {
    const request = {
      endpoint: 'abstracts',
      action: 'mylist',
      params: { eventId },
      query: '?orderBy=updatedAt',
    };

    if (!isEmpty(meta)) {
      let index = 0;
      request.query = '';
      Object.entries(meta).map(([k, v]) => {
        request.query += `${index === 0 ? '?' : '&'}${k}=${v}`;
        index++;
      });
    }
    return dispatch(api(request));
  };
export const createPerson = (eventId, data) => (dispatch) => {
  const request = {
    endpoint: 'persons',
    action: 'create',
    params: { eventId },
    body: { data },
  };

  return dispatch(api(request));
};
export const updatePerson = (eventId, data, personId) => (dispatch) => {
  const request = {
    endpoint: 'persons',
    action: 'update',
    params: { eventId, personId },
    body: { data },
  };

  return dispatch(api(request));
};

export const deleteAbstract = (eventId, abstractId) => (dispatch) => {
  const request = {
    endpoint: 'abstracts',
    action: 'delete',
    params: { eventId, abstractId },
    body: { data: {} },
  };

  return dispatch(api(request)).then((d) => {
    dispatch(addNotification(`Abstract Deleted successfully!`));
    return Promise.resolve(d);
  });
};

export const uploadFile = (
  eventId,
  action,
  file,
  params,
  callback = () => {},
  errorCallback = () => {},
  quantity
) => {
  return async (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: 'storage',
      action: 'presignedURL',
      params: {
        eventId,
      },
      body: {
        data: {
          quantity,
          action,
          name: file.name,
          type: file.type,
          size: file.size,
          ...params,
        },
      },
    };
    return dispatch(api(request, true))
      .then(async (response) => {
        return dispatch(
          uploadWithUrl(
            response.data.url,
            file,
            params,
            eventId,
            action,
            callback,
            errorCallback,
            response.data.fileName
          )
        );
      })
      .catch((e) => {
        return errorCallback(e);
      });
  };
};

export const uploadWithUrl = (
  url,
  file,
  save_params,
  eventId,
  action,
  callback,
  errorCallback,
  fileName
) => {
  return async (dispatch) => {
    const params = {
      method: 'PUT',
      body: file,
    };
    return fetch(url, params)
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Error: Something went wrong while uploading your file. Status - ${response.status}: ${response.statusText}`
          );
        }
        return dispatch(
          saveUploadedFile(
            eventId,
            save_params,
            action,
            file,
            callback,
            errorCallback,
            fileName
          )
        );
      })
      .catch((err) => {
        return errorCallback(err);
      });
  };
};

export const saveUploadedFile = (
  eventId,
  params,
  action,
  file,
  callback,
  errorCallback,
  fileName
) => {
  return async (dispatch) => {
    const request = {
      preventNotification: true,
      endpoint: 'storage',
      action: 'saveFile',
      params: {
        eventId,
      },
      body: {
        data: {
          fieldName: action,
          size: file.size,
          type: file.type,
          name: fileName,
          ...params,
        },
      },
    };

    return dispatch(api(request))
      .then((res) => {
        if (callback) return callback(res);
      })
      .catch((e) => {
        return errorCallback(e);
      });
  };
};

export const createAbstract = (eventId, data) => (dispatch) => {
  const filesToUpload = {
    abstractFiles: Object.assign([], data.abstractFiles),
  };

  if (data.abstractFiles && typeof data.abstractFiles[0] !== 'number') {
    let abstractQuery = '';

    data.abstractFiles.forEach((file) => {
      abstractQuery += file.name;
    });
    data.abstractFiles = abstractQuery;
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const request = {
    endpoint: 'abstracts',
    action: 'create',
    params: { eventId },
    body: { data },
  };
  if (!hasFiles) {
    return dispatch(api(request)).then((d) => {
      if (d.data.status === 'submitted') {
        dispatch(changeSection('abstractManagement'));
      }
      return d;
    });
  } else {
    let returned_data = {};
    return dispatch(api(request))
      .then((d) => {
        returned_data = d;
        const promiseArr = [];
        Object.keys(filesToUpload).forEach((key) => {
          filesToUpload[key].forEach((file) => {
            if (file && typeof file !== 'number') {
              promiseArr.push(
                dispatch(
                  uploadFile(
                    eventId,
                    key,
                    file,
                    { abstractId: d.data.id },
                    null,
                    (e) => {
                      return Promise.reject(e);
                    },
                    filesToUpload[key].length
                  )
                ).catch(() => {
                  return Promise.reject(new Error('Failed upload'));
                })
              );
            }
          });
        });

        return Promise.all(promiseArr).then(() => {
          if (d.data.status === 'submitted') {
            dispatch(changeSection('abstractManagement'));
          }
          return d;
        });
      })
      .catch((e) => {
        if (e.message === 'Failed upload') {
          dispatch(
            addNotification('An error occured while uploading your files', 0)
          );
          return returned_data;
        }
        return Promise.reject(e);
      });
  }
};

export const updateAbstract = (eventId, abstractId, data) => (dispatch) => {
  if (
    data.institutes.length === 1 &&
    (!data.institutes[0].name || data.institutes[0].name === '')
  )
    data.institutes = [];

  const filesToUpload = {
    abstractFiles: Object.assign([], data.abstractFiles),
  };

  if (data.abstractFiles && typeof data.abstractFiles[0] !== 'number') {
    let abstractQuery = '';

    // This is used to separate delete and upload functionality. It should always be a number or ANY string.
    data.abstractFiles.forEach((file) => {
      abstractQuery += file.name;
    });
    data.abstractFiles = abstractQuery;
  }

  if (!data.abstractFiles) {
    data.abstractFiles = '';
  }

  const filesToUploadProps = Object.keys(filesToUpload);
  let hasFiles = false;
  filesToUploadProps.forEach((key) => {
    if (filesToUpload[key]?.length > 0) hasFiles = true;
  });

  const request = {
    endpoint: 'abstracts',
    action: 'update',
    params: { eventId, abstractId },
    body: { data },
  };
  if (!hasFiles) {
    return dispatch(api(request)).then((d) => {
      if (d.data.status === 'submitted') {
        dispatch(changeSection('abstractManagement'));
      }
      return d;
    });
  } else {
    let returned_data = {};
    return dispatch(api(request))
      .then((d) => {
        returned_data = d;
        const promiseArr = [];
        Object.keys(filesToUpload).forEach((key) => {
          filesToUpload[key].forEach((file) => {
            if (file && typeof file !== 'number') {
              promiseArr.push(
                dispatch(
                  uploadFile(
                    eventId,
                    key,
                    file,
                    { abstractId: d.data.id },
                    null,
                    (e) => {
                      return Promise.reject(e);
                    },
                    filesToUpload[key].length
                  )
                ).catch(() => {
                  return Promise.reject(new Error('Failed upload'));
                })
              );
            }
          });
        });

        return Promise.all(promiseArr).then(() => {
          if (d.data.status === 'submitted') {
            dispatch(changeSection('abstractManagement'));
          }
          return d;
        });
      })
      .catch((e) => {
        if (e.message === 'Failed upload') {
          dispatch(
            addNotification('An error occured while uploading your files', 0)
          );
          return returned_data;
        }
        return Promise.reject(e);
      });
  }
};

export const deleteAbstractFile = (eventId, abstractId, data) => (dispatch) => {
  const request = {
    endpoint: 'abstracts',
    action: 'update',
    params: { eventId, abstractId },
    body: { data },
  };

  return dispatch(api(request));
};

export const updateAbstractPublishedFiles =
  (eventId, abstractId, data) => (dispatch) => {
    const filesToUpload = {
      publishedAbstractFile: Object.assign([], data.publishedAbstractFile),
      publishedAbstractThumbnail: Object.assign(
        [],
        data.publishedAbstractThumbnail
      ),
    };

    if (
      data.publishedAbstractFile &&
      typeof data.publishedAbstractFile[0] !== 'number'
    ) {
      let abstractQuery = '';

      data.publishedAbstractFile.forEach((file) => {
        abstractQuery += file.name;
      });
      data.publishedAbstractFile = abstractQuery;
    }

    if (
      data.publishedAbstractThumbnail &&
      typeof data.publishedAbstractThumbnail[0] !== 'number'
    ) {
      let abstractQuery = '';

      data.publishedAbstractThumbnail.forEach((file) => {
        abstractQuery += file.name;
      });
      data.publishedAbstractThumbnail = abstractQuery;
    }

    const filesToUploadProps = Object.keys(filesToUpload);
    let hasFiles = false;
    filesToUploadProps.forEach((key) => {
      if (filesToUpload[key]?.length > 0) hasFiles = true;
    });

    const request = {
      endpoint: 'abstracts',
      action: 'update',
      params: { eventId, abstractId },
      body: data,
      contentType: 'multipart/form-data',
    };
    if (!hasFiles) {
      return dispatch(api(request));
    } else {
      return dispatch(api(request)).then(() => {
        let returned_data = {};
        return dispatch(api(request))
          .then((d) => {
            returned_data = d;
            const promiseArr = [];
            Object.keys(filesToUpload).forEach((key) => {
              filesToUpload[key].forEach((file) => {
                if (file && typeof file !== 'number') {
                  promiseArr.push(
                    dispatch(
                      uploadFile(
                        eventId,
                        key,
                        file,
                        { abstractId: d.data.id },
                        null,
                        (e) => {
                          return Promise.reject(e);
                        },
                        filesToUpload[key].length
                      )
                    ).catch(() => {
                      return Promise.reject(new Error('Failed upload'));
                    })
                  );
                }
              });
            });

            return Promise.all(promiseArr).then(() => {
              if (d.data.status === 'submitted') {
                dispatch(changeSection('abstractManagement'));
              }
              return d;
            });
          })
          .catch((e) => {
            if (e.message === 'Failed upload') {
              dispatch(
                addNotification(
                  'An error occured while uploading your files',
                  0
                )
              );
              return returned_data;
            }
            return Promise.reject(e);
          });
      });
    }
  };

export const getSchema = (orgId, eventId, schemaId) => (dispatch) => {
  const request = {
    endpoint: 'schema',
    action: 'get',
    params: { eventId, schemaId },
  };
  return dispatch(api(request));
};

export const getPersons = (orgId) => (dispatch) => {
  const request = {
    endpoint: 'persons',
    action: 'list',
    params: { orgId },
  };
  return dispatch(api(request));
};

export const selectHotel = (hotelId) => (dispatch, getState) => {
  if (getState().menu.active !== 'hotelManagement') {
    dispatch(changeSection('hotelManagement', true));
  }
  const history = getHistory();
  history.push(`/accomodation/${hotelId}`);
  dispatch({
    type: SHOW_HOTEL,
    hotelId,
  });
};

export const selectProduct = (productId) => (dispatch, getState) => {
  if (getState().menu.active !== 'products') {
    dispatch(changeSection('productsManagement', true));
  }

  const history = getHistory();
  history.push(`/products/${productId}`);
  dispatch({
    type: SHOW_PRODUCT,
    productId,
  });
};

export const getAvailableRooms = (hotelId, meta) => {
  return (dispatch, getState) => {
    const eventId = getState().api.event.data.id;
    const request = {
      endpoint: 'hotels',
      action: 'availableRooms',
      params: { eventId, hotelId },
    };

    request.query = `?available=true&active=true&`;

    if (!isEmpty(meta)) {
      Object.entries(meta).forEach(([k, v]) => {
        if (v) request.query += `${k}=${v}&`;
      });
    }
    request.query = request.query.slice(0, -1);

    return dispatch(api(request));
  };
};
export const setProductsTabIndex = (index) => ({
  type: SET_PRODUCTS_TAB_INDEX,
  index,
});

export const createProducts = (eventId, products) => (dispatch) => {
  const request = {
    endpoint: 'products',
    action: 'create',
    params: { eventId },
    body: { data: { products } },
  };
  let d;
  return dispatch(api(request))
    .then((response) => {
      dispatch(getUser());
      dispatch(changeSection('billing', true));
      d = response;
    })
    .then(() => Promise.resolve(d));
};

export const deleteProductPurchase =
  (eventId, productId, priceId) => (dispatch) => {
    const request = {
      endpoint: 'products',
      action: 'delete',
      params: { eventId, productId, priceId },
      body: { data: {} },
    };
    let d;
    return dispatch(api(request))
      .then((response) => {
        dispatch(getUser());
        d = response;
      })
      .then(() => Promise.resolve(d));
  };

export const createHotelReservation = (
  hotelId,
  orgHotelRoomId,
  startDate,
  endDate,
  notes,
  rooms
) => {
  return (dispatch, getState) => {
    const eventId = getState().api.event.data.id;
    const request = {
      endpoint: 'hotels',
      action: 'createReservation',
      params: { eventId, hotelId },
      body: {
        data: {
          orgHotelRoomId,
          startDate,
          endDate,
          notes,
          numberOfRooms: rooms,
        },
      },
    };
    let d;
    return dispatch(api(request))
      .then((response) => {
        dispatch(getUser());
        dispatch(changeSection('billing', true));
        d = response;
      })
      .then(() => Promise.resolve(d));
  };
};

export const cancelHotelReservation = (
  eventId,
  eventHotelId,
  reservationId
) => {
  return (dispatch) => {
    const request = {
      endpoint: 'hotels',
      action: 'cancelReservation',
      params: { eventId, hotelId: eventHotelId, reservationId },
      body: {
        data: {},
      },
    };
    let d;
    return dispatch(api(request))
      .then((data) => {
        d = data;
        dispatch({
          type: SET_COUNTER,
          counter: false,
        });
        return dispatch(getUser());
      })
      .then(() => Promise.resolve(d));
  };
};

export const getHotels = (
  hasReservation = 0,
  endpoint = 'hotels',
  action = 'list',
  meta
) => {
  return (dispatch, getState) => {
    const eventId = getState().api.event.data.id;
    const request = {
      endpoint,
      action,
      params: { eventId },
    };
    request.query = `?client=true&hasReservation=${hasReservation == 1}&`;

    if (!isEmpty(meta)) {
      Object.entries(meta).map(([k, v]) => {
        if (v) {
          request.query += `${k}=${v}&`;
        }
      });
      request.query = request.query.slice(0, -1);
    }

    return dispatch(api(request));
  };
};

export const getUser = (endpoint = 'user', action = 'get') => {
  return (dispatch, getState) => {
    const request = {
      endpoint,
      action,
    };
    const state = getState();
    const policy = state.api.policy.data;
    return dispatch(api(request))
      .then((d) => {
        dispatch(setupMenu(undefined, policy, undefined, d));
        return Promise.resolve(d);
      })
      .catch(console.error);
  };
};

export const addNotification = (message, className = 1) => {
  return (dispatch) => {
    switch (className) {
      case 0:
        className = 'danger';
        break;
      case 1:
        className = 'success';
        break;
      case 2:
        className = 'warning';
        break;
    }

    dispatch({
      type: ADD_NOTIFICATION,
      message,
      className,
    });

    setTimeout(() => {
      dispatch({ type: REMOVING_NOTIFICATION });
    }, notificationTimeout - 1000);

    setTimeout(() => {
      dispatch({ type: REMOVE_NOTIFICATION });
    }, notificationTimeout);
  };
};

export const showSpeaker = (speakerId) => {
  return (dispatch, getState) => {
    const state = getState();

    const history = getHistory();
    history.push(`/speakers/${speakerId}`);

    if (state.menu.active !== 'speakers') {
      dispatch(changeSection('speakers', true));
    }

    dispatch({
      type: SHOW_SPEAKER,
      speakerId,
    });
  };
};

export const showExhibitor = (exhibitorId) => {
  return (dispatch, getState) => {
    const state = getState();

    const history = getHistory();
    history.push(`/exhibition/${exhibitorId}`);

    if (state.menu.active !== 'exhibition') {
      dispatch(changeSection('exhibition', true));
    }

    dispatch({
      type: SHOW_EXHIBITION,
      exhibitorId,
    });
  };
};
export const loadSpeaker = (endpoint = 'speakers') => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.event.data.id;
    const speakerId = state.api.speakers.showingSpeaker.speakerId;
    const request = {
      endpoint,
      action: 'getSingle',
      params: {
        eventId,
        speakerId,
      },
    };
    dispatch(api(request));
  };
};

export const loadSpeakers = (
  meta = { order: 'DESC', orderBy: 'lastName', rpp: '-1' },
  endpoint = 'speakers'
) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.event.data.id;
    const cache = checkMeta(meta, state.api.speakers.meta);
    meta.orderBy = 'lastName';
    meta.order = 'ASC';
    const request = {
      endpoint,
      action: 'get',
      params: {
        eventId,
      },
    };

    if (cache) {
      return dispatch({
        type: 'CACHED',
      });
    }

    if (!isEmpty(meta)) {
      request.query = `?`;
      Object.entries(meta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    dispatch(api(request));
  };
};

export const addToAgenda = (sessionId, remove = false, endpoint = 'user') => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.event.data.id;
    const policyId = state.api.policy.data.id;
    const userId = state.api.user.data.id;
    let favouriteSessions = state.api.user.data.info.hasOwnProperty(
      'favouriteSessions'
    )
      ? state.api.user.data.info.favouriteSessions
      : '';
    favouriteSessions = favouriteSessions.split(',').filter((s) => s);
    if (remove) {
      favouriteSessions = favouriteSessions.filter(
        (s) => s !== sessionId.toString()
      );
    } else {
      favouriteSessions = [...favouriteSessions, sessionId.toString()];
    }

    favouriteSessions = favouriteSessions.join(',');

    const data = { info: { favouriteSessions } };

    const request = {
      endpoint,
      action: 'addToAgenda',
      params: {
        eventId,
        policyId,
        userId,
      },
      body: { data },
    };

    dispatch(api(request))
      .then(() => {
        return;
      })
      .catch((e) => {
        console.error(e);
      });
  };
};
export const getBadge = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: 'badge',
      action: 'get',
      params: { eventId },
    };
    return dispatch(api(request)).catch(() => {
      dispatch({
        type: RECEIVED_BADGE_GET_ERROR,
      });
    });
  };
};
export const getAppleWalletBadge = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: 'badgeAppleWallet',
      action: 'get',
      params: { eventId },
    };
    return dispatch(api(request)).catch(() => {
      dispatch({
        type: RECEIVED_BADGE_GET_ERROR,
      });
    });
  };
};
export const getGoogleWalletBadge = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: 'googleWalletPass',
      action: 'get',
      params: { eventId },
    };
    return dispatch(api(request)).catch(() => {
      dispatch({
        type: RECEIVED_BADGE_GET_ERROR,
      });
    });
  };
};
export const getCert = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: 'user',
      action: 'getCert',
      params: { eventId },
    };
    dispatch(api(request));
  };
};
export const userInSession = (sessionId, remove = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.event.data.id;
    const userId = state.api.user.data.id;
    let request = {};
    if (remove) {
      request = {
        endpoint: 'sessions',
        action: 'deleteUser',
        params: {
          eventId,
          sessionId,
          userId,
        },
        body: { data: [] },
      };
    } else {
      request = {
        endpoint: 'sessions',
        action: 'addUser',
        params: {
          eventId,
          sessionId,
        },
        body: { data: { userId } },
      };
    }

    dispatch(api(request))
      .then(() => {
        dispatch(
          addNotification(
            `Session succesfully ${
              remove ? 'removed from' : 'added to'
            } your agenda!`
          )
        );
        const { rpp, p, order, orderBy, users } = state.api.sessions.meta;
        let meta = undefined;
        if (rpp) {
          meta = { rpp, p, orderBy, order, users };
        }
        return dispatch(loadSessions(meta));
      })
      .catch(() => {
        const { rpp, p, order, orderBy, users } = state.api.sessions.meta;
        let meta = undefined;
        if (rpp) {
          meta = { rpp, p, orderBy, order, users };
        }
        return dispatch(loadSessions(meta));
      });
  };
};

export const showSpeeches = (sessionId) => {
  return (dispatch, getState) => {
    if (getState().menu.active !== 'schedule') {
      dispatch(changeSection('schedule', true));
    }
    const history = getHistory();
    history.push(`/schedule/${sessionId}`);
    dispatch({
      type: SHOW_SPEECHES,
      sessionId,
    });
  };
};
export const showSponsor = () => {
  return (dispatch, getState) => {
    if (getState().menu.active !== 'sponsors') {
      dispatch(changeSection('sponsors', true));
    }
    const history = getHistory();
    history.push(`/sponsors`);
    dispatch({
      type: SHOW_SPONSOR,
    });
  };
};

export const showMyAbstracts = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_MY_ABSTRACTS,
    });
  };
};
export const showPublishedAbstracts = () => {
  return (dispatch) => {
    dispatch({
      type: SHOW_PUBLISHED_ABSTRACTS,
    });
  };
};

export const loadVideos = (
  eventId,
  meta = { order: 'DESC', orderBy: 'id', rpp: '10', p: '1' }
) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      meta.users = true;
      const state = getState();
      const request = {
        endpoint: 'videos',
        action: 'get',
        params: {
          eventId: state.api.policy.data.eventId,
        },
      };
      if (!isEmpty(meta)) {
        request.query = `?`;
        Object.entries(meta).map(([k, v]) => {
          if (k === 'users') return;
          request.query += `${k}=${v}&`;
        });
        request.query = request.query.slice(0, -1);
      }

      return dispatch(api(request))
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          console.error(err);
          reject(err);
        });
    });
  };
};

export const loadSessions = (
  meta = { order: 'ASC', orderBy: 'startDate', rpp: '5' },
  cachePage = false
) => {
  return (dispatch, getState) => {
    meta.users = true;
    let state = getState();
    if (cachePage) {
      const { rpp, p } = state.api.sessions.meta;
      meta.rpp = rpp || meta.rpp;
      meta.p = p || meta.p || 1;
    }
    const request = {
      endpoint: 'sessions',
      action: 'get',
      params: {
        eventId: state.api.policy.data.eventId,
      },
    };

    if (!isEmpty(meta)) {
      request.query = `?`;
      Object.entries(meta).map(([k, v]) => {
        request.query += `${k}=${v}&`;
      });
      request.query = request.query.slice(0, -1);
    }

    return dispatch(api(request))
      .then(() => {
        state = getState();
        const calendar = buildSessionsCalendar(
          state.api.event.data,
          state.api.sessions.data
        );

        dispatch({ type: BUILD_SESSIONS_CALENDAR, calendar });
      })
      .catch((err) => console.error(err));
  };
};

export const loadSessionById = (sessionId) => {
  return (dispatch, getState) => {
    const state = getState();
    const request = {
      endpoint: 'sessions',
      action: 'getById',
      params: {
        eventId: state.api.policy.data.eventId,
        sessionId,
      },
    };

    return dispatch(api(request)).catch((err) => console.error(err));
  };
};

export const noteLastProhibitedURL = (url) => {
  return (dispatch) => {
    return dispatch({
      type: NOTE_LAST_PROHIBITED_URL,
      url,
    });
  };
};

export const setHasUnsavedChanges = (status) => {
  return (dispatch) => {
    return dispatch({
      type: HAS_UNSAVED_CHANGES,
      status,
    });
  };
};
export const setHasRequestedChangeSection = (status) => {
  return (dispatch) => {
    return dispatch({
      type: HAS_REQUESTED_CHANGE_SECTION,
      status,
    });
  };
};

export const changeSection = (section, popstate = false, pageProps) => {
  return (dispatch, getState) => {
    const history = getHistory();
    const active = getState().menu.active;
    const hasUnsavedChanges = getState().api.hasUnsavedChanges;

    let { pathname } = history.location;

    if (hasUnsavedChanges) {
      dispatch(setHasRequestedChangeSection(section));
      return false;
    } else {
      if (section === 'abstractManagement') {
        dispatch(showPublishedAbstracts());
      }
      // this navigates the user back to the main page if they are on a subPage
      if (section === active) {
        pathname = pathname.split('/').filter((n) => n);
        if (pathname.length > 1) history.push(`/${pathname[0]}`);
      } else if (section === 'generalInfo') {
        history.push(`/`);
      }
      dispatch(setHasRequestedChangeSection(false));
    }

    return dispatch({
      type: CHANGE_SECTION,
      section,
      popstate,
      pageProps,
    });
  };
};

export const loadEvent = (eventId, policy, freeAccess, section = 'info') => {
  return (dispatch, getState) => {
    const request = {
      endpoint: 'event',
      action: 'get',
      params: {
        eventId,
        section,
      },
    };

    dispatch(api(request)).then((response) => {
      const state = getState();
      const user = state.api.user;
      return dispatch(setupMenu(response, policy, freeAccess, user));
    });
  };
};

export const getEventInfo = (eventId) => {
  return (dispatch) => {
    const request = {
      endpoint: 'event',
      action: 'get',
      params: {
        eventId,
        section: 'info',
      },
    };

    return dispatch(api(request));
  };
};

export const setupMenu = (event, policy, freeAccess, user) => {
  return (dispatch, getState) => {
    const state = getState();
    event = event || state.api.event;
    user = user || state.api.user;
    const language = state.api.language.selected || 'en';

    return dispatch({
      type: SETUP_MENU,
      event: event.data,
      policy,
      freeAccess,
      user,
      language,
    });
  };
};

export const userLoggedIn = (user, eventId, accessToken) => {
  const tokenName = buildTokenName(eventId, user.eventPolicyId);
  localStorage.setItem(tokenName, accessToken);

  return {
    type: USER_LOGGED_IN,
    user,
    accessToken,
    pseudo: user.pseudo,
  };
};

export const registerUser = (
  fields,
  token,
  endpoint = 'policies',
  action = 'register',
  orgCrmUserId,
  products = []
) => {
  return (dispatch, getState) => {
    const state = getState();
    const policyId = state.api.policy.data.id;
    const eventId = state.api.policy.data.eventId;
    const info = {};

    for (const [k] of Object.entries(fields)) {
      if (fields[k] === 1) {
        info[k] = true;
      } else if (fields[k] === 0) {
        info[k] = false;
      } else {
        info[k] = fields[k];
      }
    }

    const request = {
      endpoint,
      action,
      params: {
        policyId,
        eventId,
      },
      body: {
        data: {
          info,
          token,
          orgCrmUserId: orgCrmUserId,
          products,
        },
      },
      insecure: true,
    };

    if (!request.body.data['orgCrmUserId']) {
      delete request.body.data['orgCrmUserId'];
    }
    return dispatch(api(request))
      .then((res) => {
        dispatch(addNotification(`Registration completed successfully!`));
        return res;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: REGISTRATION_FAILED,
          message: err.message || err,
        });
        return Promise.reject(err);
      });
  };
};

export const calculateSubscription = (
  fields,
  token,
  endpoint = 'policies',
  action = 'calculateSubscription'
) => {
  return (dispatch, getState) => {
    const state = getState();
    const policyId = state.api.policy.data.id;
    const eventId = state.api.policy.data.eventId;
    const info = {};

    for (const [k] of Object.entries(fields)) {
      info[k] = fields[k];
    }
    const request = {
      endpoint,
      action,
      params: {
        policyId,
        eventId,
      },
      body: { data: { info, token } },
      insecure: true,
    };

    return dispatch(api(request))
      .then((response) => {
        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: REGISTRATION_FAILED,
          message: err.message || err,
        });
        return false;
      });
  };
};
export const getProducts = (
  token,
  endpoint = 'policies',
  action = 'calculateSubscription'
) => {
  return (dispatch, getState) => {
    const state = getState();
    const policyId = state.api.policy.data.id;
    const eventId = state.api.policy.data.eventId;
    const request = {
      endpoint,
      params: {
        policyId,
        eventId,
      },
      body: { data: { token } },

      action,
      insecure: true,
    };

    return dispatch(api(request))
      .then((response) => {
        return response.data.availableProducts;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: REGISTRATION_FAILED,
          message: err.message || err,
        });
        return false;
      });
  };
};

export const getExhibitors = (meta) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.policy.data.eventId;
    const request = {
      endpoint: 'exhibition',
      params: {
        eventId,
      },
      action: 'list',
    };
    if (!isEmpty(meta)) {
      let index = 0;
      request.query = '';
      Object.entries(meta).map(([k, v]) => {
        request.query += `${index === 0 ? '?' : '&'}${k}=${v}`;
        index++;
      });
    }
    return dispatch(api(request))
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err);

        return false;
      });
  };
};
export const getExhibitorById = (id) => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.policy.data.eventId;
    const request = {
      endpoint: 'exhibition',
      params: {
        eventId,
        exhibitorId: id,
      },
      action: 'get',
    };

    return dispatch(api(request))
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error(err);

        return false;
      });
  };
};
export const getProductsClient = () => {
  return (dispatch, getState) => {
    const state = getState();
    const eventId = state.api.policy.data.eventId;
    const request = {
      endpoint: 'products',
      action: 'list',
      params: {
        eventId,
      },
    };
    dispatch(api(request));
  };
};
export const login = (
  eventId,
  policyId,
  fields,
  endpoint = 'policies',
  action = 'login'
) => {
  return (dispatch, getState) => {
    for (const [k, f] of Object.entries(fields)) {
      if ((fields[k].value || '').length === 0) {
        delete fields[k];
        continue;
      }
      fields[k] = fields[k].type === 'yes_no' ? f.value === 'yes' : f.value;
    }

    const request = {
      endpoint,
      action,
      params: {
        eventId,
        policyId,
      },
      body: {
        data: fields,
      },
      insecure: true,
    };

    dispatch(api(request))
      .then(async (response) => {
        const state = getState();
        const event = state.api.event;
        const policy = state.api.policy.data;
        await dispatch(
          setupMenu(event, policy, event.data.freeAccess, response)
        );
        await dispatch(resetFailCounter('login'));
        await dispatch(
          userLoggedIn(response.data, eventId, response.meta.accessToken)
        );
        if (state.api.lastProhibitedUrl) {
          //! TODO: This should await the dispatch userLoggedIn action to complete before reloading the page

          if (
            state.api.lastProhibitedUrl !== location.href &&
            state.api.lastProhibitedUrl !==
              `${location.origin}/${location.pathname.substring(
                0,
                location.pathname.indexOf('/')
              )}/`
          ) {
            setTimeout(() => {
              window.location.replace(state.api.lastProhibitedUrl);
            }, 500);
          }
        } else {
          dispatch(changeSection('generalInfo'));
        }
      })
      .catch((err) => {
        dispatch(captchaFailCounter('login'));
        dispatch({
          type: LOGIN_FAILED,
          message: err.message,
        });
      });
  };
};

export const validateUrl = (url, path) => {
  return (dispatch) => {
    if (path.length === 0) {
      return go404();
    }

    dispatch(fetchPolicy(url, path));
  };
};

export const fetchPolicy = (url, path, endpoint = 'policies') => {
  return (dispatch) => {
    const request = {
      endpoint,
      action: path.length === 1 ? 'getDefault' : 'get',
      params: {
        urlAlias: path[0],
      },
      insecure: true,
    };

    if (path.length === 2) {
      request.params['policyId'] = path[1];
    }

    return dispatch(api(request)).then((policy) => {
      if (
        policy.data.hasOwnProperty('freeAccess') &&
        policy.data.freeAccess === 1
      ) {
        let resetPasswordAvailable = false;
        for (const field of policy.data.fields || []) {
          if (field.key === 'email') {
            resetPasswordAvailable = true;
            break;
          }
        }
        dispatch(setupData(url, path, policy, resetPasswordAvailable));
        return dispatch(checkIfLoggedIn(policy.data, policy.meta.accessToken))
          .then((res) => {
            const { user, accessToken } = res;
            dispatch(userLoggedIn(user, user.eventId, accessToken));
          })
          .catch(() => {
            dispatch(setupData(url, path, policy, false));
            dispatch(
              userLoggedIn(
                {
                  info: {
                    firstName: 'Guest',
                    lastName: '',
                  },
                  pseudo: true,
                },
                policy.data.eventId,
                policy.meta.accessToken
              )
            );
          });
      }
      let resetPasswordAvailable = false;
      for (const field of policy.data.fields) {
        if (field.key === 'email') {
          resetPasswordAvailable = true;
          break;
        }
      }
      dispatch(setupData(url, path, policy, resetPasswordAvailable));
      return dispatch(checkIfLoggedIn(policy.data))
        .then((res) => {
          const { user, accessToken } = res;
          dispatch(userLoggedIn(user, user.eventId, accessToken));
        })
        .catch(() => {
          const query = queryString.parse(location.search, {
            ignoreQueryPrefix: true,
          });

          if (Object.keys(query).length < 1) {
            dispatch(noteLastProhibitedURL(window.location.href));
            const history = getHistory();
            history.push(`/login`);
          }

          dispatch({ type: USER_NOT_LOGGED_IN });
        });
    });
  };
};

export const logout = (reload = false) => {
  return (dispatch, getState) => {
    const state = getState();
    const policyId = state.api.policy.data.id;
    const eventId = state.api.policy.data.eventId;
    const tokenName = buildTokenName(eventId, policyId);
    dispatch({
      type: USER_LOGOUT,
    });

    localStorage.removeItem(tokenName);

    dispatch({
      type: USER_LOGOUT,
    });

    if (reload) {
      return window.location.reload();
    }
    dispatch(changeSection('login'));
  };
};

export const checkIfLoggedIn = (policy, token) => {
  // check if token exists on ls.

  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const tokenName = buildTokenName(policy.eventId, policy.id);
      if (token) {
        const accessToken = localStorage.getItem(tokenName);
        if (!accessToken) {
          localStorage.setItem(tokenName, token);
        }
      }
      const accessToken = localStorage.getItem(tokenName);
      if (accessToken === null) return reject(new Error());

      // validate token
      const request = {
        endpoint: 'user',
        action: 'get',
        accessToken,
      };

      return dispatch(api(request))
        .then((response) => {
          if (response.status !== 200) {
            return reject(response);
          }

          resolve({ user: response.data, accessToken });
          dispatch(getUser());
        })
        .catch((e) => {
          if (e.status === 404) {
            localStorage.removeItem(tokenName);
          }
          return reject(e);
        });
    });
  };
};
export const validateToken = (token) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const policyId = state.api.policy.data.id;
      const eventId = state.api.policy.data.eventId;

      const request = {
        endpoint: 'validateToken',
        action: 'post',
        params: {
          eventId,
          policyId,
        },
        body: { data: { token } },
        insecure: true,
      };
      return dispatch(api(request))
        .then((response) => {
          if (response.status !== 200) {
            return reject(new Error());
          }
          resolve(response);
        })
        .catch(reject);
    });
  };
};

export const updateInViewMenuItem = (key, status) => {
  return (dispatch) => {
    return dispatch({
      type: UPDATE_IN_VIEW_MENU_ITEM,
      key,
      status,
    });
  };
};

export const verifyEmail = (email) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const policyId = state.api.policy.data.id;
      const eventId = state.api.policy.data.eventId;

      const request = {
        endpoint: 'verifyEmail',
        action: 'post',
        params: {
          eventId,
          policyId,
        },
        body: { data: { email } },
        insecure: true,
      };
      return dispatch(api(request))
        .then((response) => {
          if (response.status !== 200) {
            return reject(new Error());
          }
          if (!response.data.registrationCompleted) {
            dispatch(changeScreen('login'));
            dispatch(setcooldown('register'));
            dispatch(
              addNotification(
                `To complete your registration please follow the instructions in the email we have sent you.`
              )
            );
          } else {
            dispatch(changeSection('login'));
            dispatch(changeScreen('login'));
            dispatch(
              addNotification(
                `You are already assigned to the event. Please login or request a new password.`,
                0
              )
            );
          }
          dispatch(resetFailCounter('register'));
          resolve(response);
        })
        .catch((err) => {
          dispatch(captchaFailCounter('register'));
          reject(err);
        });
    });
  };
};

export const reduceCooldown = (flow) => {
  return (dispatch, getState) => {
    const state = getState();

    const remainingCooldown = Math.floor(
      state.api.requestThrottle[flow].targetCooldown -
        moment().diff(state.api.requestThrottle[flow].lastTimestamp) / 1000
    );

    let finalValue = 0;
    if (state.api.requestThrottle[flow].cooldown <= 0) {
      finalValue = 0;
      dispatch(clearCooldown(state.api.requestThrottle[flow].timeout, flow));
    } else {
      finalValue = remainingCooldown;
    }

    dispatch({
      flow,
      type: REDUCE_COOLDOWN,
      cooldown: finalValue,
    });
  };
};

export const setcooldown = (flow) => (dispatch, getState) => {
  const state = getState();

  let cooldown = 0;
  switch (state.api.requestThrottle[flow].iteration) {
    case 0:
    case 1:
    case 2:
      cooldown = 60;
      break;
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
    case 8:
    case 9:
      cooldown = 300;
      break;
    default:
      cooldown = 600;
      break;
  }

  dispatch({
    type: SET_COOLDOWN,
    flow,
    cooldown: cooldown,
    targetCooldown: cooldown,
    lastTimestamp: moment(),
    timeout: setInterval(() => {
      dispatch(reduceCooldown(flow));
    }, 1000),
  });
};

export const clearCooldown = (timeout_id, flow) => (dispatch) => {
  clearTimeout(timeout_id);
  dispatch({
    type: CLEAR_COOLDOWN,
    flow,
    timeout: null,
  });
};

export const requestResetPassword = (data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const email = data.value;
      if (!email || email.length === 0) {
        return dispatch(addNotification(`Email is not valid!`, 0));
      }
      const state = getState();
      const policyId = state.api.policy.data.id;
      const eventId = state.api.policy.data.eventId;

      const request = {
        endpoint: 'user',
        action: 'requestResetPassword',
        params: {
          eventId,
          policyId,
        },
        body: { data: { email } },
        insecure: true,
      };

      return dispatch(api(request))
        .then((response) => {
          if (response.status !== 200) {
            return reject(new Error());
          } else {
            dispatch(setcooldown('password'));
            dispatch(changeScreen('forgotPassSuccess', email));
            dispatch(
              addNotification(
                'An email has been sent to you with a link to reset your password!'
              )
            );
          }
          dispatch(resetFailCounter('login'));
          resolve(response);
        })
        .catch((err) => {
          dispatch(captchaFailCounter('login'));
          reject(err);
        });
    });
  };
};

export const resetPassword = (fields, token, email) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const policyId = state.api.policy.data.id;
      const eventId = state.api.policy.data.eventId;

      const request = {
        endpoint: 'user',
        action: 'resetPassword',
        params: {
          eventId,
          policyId,
        },
        body: { data: { password: fields.password.value, token } },
        insecure: true,
      };

      return dispatch(api(request))
        .then((response) => {
          if (response.status !== 200) {
            return reject(new Error());
          } else {
            dispatch(addNotification(`Password changed successfully.`));
            dispatch(
              changeScreen('resetPassSuccess', email, fields.password.value)
            );
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  };
};

export const changeScreen = (screen, email, password) => {
  return {
    type: CHANGE_SCREEN,
    screen,
    email,
    password,
  };
};

export const togglePasswordVisibility = (key) => {
  return {
    type: TOGGLE_PASSWORD_VISIBILITY,
    key,
  };
};

export const getTwillioToken = (eventId, sessionId, name) => (dispatch) => {
  const request = {
    endpoint: 'twillio',
    action: 'post',
    params: { eventId, sessionId },
    body: {
      data: {
        device: 'Browser',
        identity: name,
      },
    },
  };
  return dispatch(api(request));
};

export const setupData = (url, path, policy, resetPasswordAvailable) => {
  return {
    type: SETUP_DATA,
    url,
    path,
    policy,
    resetPasswordAvailable,
  };
};

export const watchtimeStatistics = (eventId, data) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const eventId = state.api.policy.data.eventId;

      const request = {
        endpoint: 'watchtime',
        action: 'create',
        params: {
          eventId,
        },
        body: { data },
      };

      return dispatch(api(request))
        .then((response) => {
          if (response.status !== 200) {
            return reject(new Error());
          }
          resolve(response);
        })
        .catch((err) => reject(err));
    });
  };
};

export const createCustomEmail = (eventId, data) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      const request = {
        endpoint: 'presetEmails',
        action: 'custom',
        params: { eventId },
        body: {
          data,
        },
      };

      return dispatch(api(request))
        .then((response) => {
          dispatch(resetFailCounter('message'));
          resolve(response);
        })
        .catch((err) => {
          dispatch(captchaFailCounter('message'));
          reject(err);
        });
    });
  };
};

export const setCaptchaCheck = (token, componentToVerify) => {
  //! Mock token validity check call to BE. Until serverside verification is implemented this mock function will always return true.
  const tokenValidity = (() => {
    return !!token;
  })();

  return {
    type: SET_CAPTCHA_TOKEN,
    token,
    tokenValidity,
    componentToVerify,
  };
};

export const captchaFailCounter = (recaptchaSection) => {
  return {
    type: CAPTCHA_FAIL_COUNTER,
    recaptchaSection,
  };
};

export const resetFailCounter = (recaptchaSection) => {
  return {
    type: RESET_FAIL_COUNTER,
    recaptchaSection,
  };
};
