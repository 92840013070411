module.exports = {
  en: {
    unsavedChangesMessage: 'You have unsaved changes!',
    unsavedChangesBody:
      'If you click <strong>"STAY"</strong>, your changes will be retained, allowing you to continue editing. Alternatively, selecting <strong>"DISCARD AND PROCEED"</strong> will discard any unsaved changes, allowing you to proceed with your intended action.',
    discardAndProceedPlaceholder: 'DISCARD AND PROCEED',
    stayPlaceholder: 'STAY',
    abstractTitlePlaceholder: "Type your abstract's title",
    abstractContentPlaceholder: "Type your abstract's content",
    abstractTopicsPlaceholder: "Select your abstract's topic",
    abstractTypePlaceholder: "Select your abstract's type",
    abstractInstitutesPlaceholder: 'Type your institutes name',
    abstractAuthorFirstNamePlaceholder: "Type the author's first name",
    abstractAuthorLastNamePlaceholder: "Type the author's last name",
    abstractAuthorEmailPlaceholder: "Type the author's email",
    abstractAuthorInstitutePlaceholder: "Select the author's institute",
    typePlaceholder: 'Type...',
    selectPlaceholder: 'Select...',
    unsupportedFileType: 'Unsupported file type',
    pdfFile: 'PDF',
    videoFile: 'Video',
    imageFile: 'Image',
    pleaseFillRequiredFields: 'Please fill in all required fields.',
    previous: 'Previous',
    previousStep: 'Previous Step',
    next: 'Next',
    nextStep: 'Next Step',
    cancelRegistration: 'Cancel Registration',
    generalInfoRegistration: 'General Info',
    additionalProductsBenefits: 'Additional Products/Benefits',
    checkOutPayment: 'Checkout',
    additionalProducts:
      'You may select additional products/benefits to safeguard your spot.',
    exclusiveInformation:
      'The information you provide will be used exclusively to organize this conference. For more information you can refer to our terms and conditions in the final step, or contact our administration.',
    searchByTitle: 'Title',
    itemOverbooked: 'Available quantity exceeded!',
    itemSoldOut: 'This product has been sold out!',
    selectVenue: 'Room',
    selectSpeaker: 'Speaker',
    paymentOptions: 'Payment Options',
    extraFeatures: 'More...',
    question: 'Question',
    videoFormText: 'Type your name and the question to the speaker',
    userNameWarning: 'Attention: This name will appear on your certificate.',
    questionsText: 'Click here if you want to ask a question via email',
    questionsTextError: 'Otherwise send an email with the question to',
    deleteAbstract: 'You are about to delete this abstract.',
    deleteDescription: 'This action is irreversible.',
    ondemandTitle: 'ON DEMAND CONFERENCE TRANSMISSION',
    onDropRejected:
      'Sorry but the file you tried to upload has an invalid file type. Accepted file types',
    typeMessage: 'Type your message',
    typeYourName: 'Type your name',
    yourMessage: 'Your message',
    message: 'Message',
    fileInfoDimentions: 'Dimentions',
    maxFileSize: 'Max File Size',
    fileSize: 'File size',
    totalCost: 'Total Cost',
    categories: 'Categories',
    uploadPublishedThumbnail: 'Upload thumbnail for your published abstract',
    uploadPublishedFiles: 'Upload the file of your published abstract',
    uploadFiles: 'Uploaded Files',
    pleaseAgreeToTerms:
      'To continue, please agree to the terms and conditions.',
    checkValidFieldErrorMsg: 'Please fill in all required fields',

    filesLimitReached:
      'The limit of number of files has been reached. Please delete a file, before you can upload a new one. The Limit of number of files is',
    loader: 'Loader',
    resetMapCenter: 'Reset Map Center',
    rowsPerPage: 'Rows per page',
    of: 'of',
    select: 'Select',
    myCart: 'My Cart',
    emptyCart: 'Empty Cart',
    checkin: 'Check-in',
    checkout: 'Check-out',
    checkIn_checkOut: 'Check-in - Check-out',
    firstName: 'First Name',
    lastName: 'Last Name',
    institute: 'Institute',
    institutes: 'Institutes',
    addInstitutes: 'Add Institute',
    email: 'Email',
    download: 'Download',
    sheduleDownloadInfo: 'In case you want to download the schedule',
    speakerInfo: 'No speaker information available',
    clickHere: 'click here',
    clickHereCaps: 'Click Here',
    here: 'here',
    checkIn: 'Check-in Date',
    checkOut: 'Check-out Date',
    guests: '# of Guests',
    maxPricePerNight: 'Maximum price per night',
    applyFilters: 'Apply Filters',
    hotelRank: 'Minimum Hotel Rank',
    addAuthors: 'Add Author',
    abstractTitle: 'Abstract Title',
    abstractContent: 'Abstract Content',
    fileName:
      'The filename should be in Latin characters and must not contain spaces or any illegal characters (*, /, ?, +, etc).',
    mainAuthor: '* Corresponding/Main Author',
    main: 'Main',
    author: 'Author',
    concurrentLogin1: 'Your account has been logged on from another device.',
    concurrentLogin2: 'You have been logged out from your account.',
    createAbstract: 'Create New Abstract',
    createNew: 'Create New',
    hi: '',
    registrationNotCompleted: 'your registration is not completed!',
    abstractDuesMessage:
      'Please complete any pending dues regarding your registration in order to submit an Abstract!',
    videoDuesMessage:
      'Please complete any pending dues regarding your registration in order to watch a Video!',

    goToPayments: 'Go to Payments',
    goToAccount: 'Go to Account',

    invalidEmail: 'Invalid Email',
    topics: 'Abstract Topics',
    types: 'Presentation Type',
    draft: 'Draft',
    newAbstractStatus: 'New Abstract',
    approved: 'Approved',
    rejected: 'Rejected',
    awaiting_resubmission: 'Awaiting Resubmission',
    noAbstractSchema: 'No Abstract Schema found!',
    noProducts: 'No products found!',
    noMyProducts: 'You have not purchased any products!',
    abstractSubmitted:
      'The Abstract has been submitted, so no further editing is allowed.',
    submitted: 'Submitted',
    under_review: 'Under Review',
    mailDuplicateText: 'You will receive a copy of this message in your email.',
    judgeComments: 'Judge Comments',
    submissionRules: 'Submission Rules',
    starts: 'Starts',
    ends: 'Ends',
    contactInformation: 'Contact Information',
    phoneNumber: 'Phone Number',
    otherInfo: '',
    dates: 'Dates',
    title: 'Title',

    facilities: 'Facilities',
    pendingPayments: 'Pending Payments',
    paymentsHistory: 'Payments History',
    noPaymentsHistory: 'There are not any completed transactions',
    description: 'Description',
    suitableFor: 'Suitable for',
    notAvailable: 'Not Available',
    available: 'Available',
    orderTotal: 'Order Total',
    importantNotes: 'Please type in any important notes',
    alreadyWatching:
      'You are currently in a venue room. You may continue watching this video but your minutes will not be accounted for.',
    payYourRegistration:
      'Please complete any pending dues regarding your registration',
    completedReservation: 'You have completed your Reservation!',
    proceedToPurchase: 'Proceed to Purchase',
    proceedPaymentReservation:
      'Proceed to Payment to Complete your Reservation!',
    goToPaymentsCompletePayment:
      'Go to payments in order to complete your payment!',
    registration: 'Register',
    greetingRegistration: 'Hi, your registration is a few steps away!',
    greetingValidation: 'Hi, the validation of your data is a few steps away!',
    overview: 'Overview',
    view: 'View',
    checkAvailabilityMessage: 'Select dates to check availability',
    date: 'Date',
    payDue: 'Pay due amount with',
    paymentTitle: 'Make a payment',
    filters: 'FILTERS',
    selectRoom: 'Select Room',
    reservedRoom: 'Reserved Room',
    roomTypes: 'Room Types',
    bookNowFor: 'Book now for',
    bookNow: 'Book now',
    product: 'Product',
    haveHotelReservation: 'You have a hotel reservation',
    hotels: 'Hotels',
    completeRegistration: 'Complete Registration',
    complete: 'COMPLETE',
    validateRegistration: 'Validate Registration',
    completeRegistrationMessage:
      "To complete your registration, please agree to our terms of service and click 'Complete Registration'",
    validateRegistrationMessage:
      "To validate your registration info, please agree to our terms of service and click 'Validate Registration'",

    country: 'Country',
    state: 'State',
    city: 'City',
    zipCode: 'Zip Code',
    address: 'Address',
    choosePay: 'Choose a way to pay',
    termsOfUse: 'Terms of Service',
    disclaimerPolicy: 'Policy Disclaimer',
    cancellationPolicy: 'Cancellation Policy',
    agreeTermsOfService: 'I agree to the terms of service',
    transactionCompleted: 'Transaction Completed Successfully!',
    orderId: 'Order Id',
    termsOfService: 'Terms Of Service',
    update: 'Update',
    remove: 'Remove',
    payoff: 'Payoff',
    numberOfProductsInCart: 'Number of products in cart: ',
    maxQuantityReached: 'Max quantity reached',
    editYourProfile: 'Edit your profile',
    paymentReference: 'Payment Reference',
    transactionRefused: 'Transaction Refused!\nPlease repeat the process!',
    transactionFailed: 'Transaction Not Completed!\nPlease repeat the process!',
    duesMessage:
      'You have pending dues. Please select a way in order to complete any transaction needed.',
    paymentsCard:
      'Want to complete your pending dues by Credit or Debit Card ?',
    selectProducts:
      'Please select one or more products to proceed with a payment',
    stars: 'Stars',
    viewAll: 'View All',
    contactUsMessage:
      'If you have any questions regarding payments please contact us at',
    noPendingPayments: 'You have no pending payments!',
    shareAbstract: 'Share Abstract',
    copyLink: 'Copy link',
    waitUntilFinish:
      'We are processing your request. This might take several minutes. Please do not close this page.',
    proceedPayment: 'Proceed to Payment',
    paymentsBank: 'Want to complete your pending dues by Bank Transfer ?',
    bankInfo: 'Bank Info',
    iban: 'IBAN',
    creditCard: 'Credit or Debit Card',
    startingFrom: 'available for',
    purchasedAt: 'purchased at',
    confirmedPayment: 'Paid',
    requiredField: 'This field is required!',
    bankMessage:
      "Any payments of way 'Bank Transfer' will be set to completed soon as the transaction is completed and verified by the Congress Team.",
    paymentsContactUsMessage:
      'If you have any questions regarding payments please contact us at',
    paymentsRegistrationFee:
      'How is my Registration Fee calculated and how can I change it?',
    paymentsRegistrationFeeViewMessage: `Below you can view all the available Registration Type Fees. The one that suits you will be checked.`,
    contactUsPaymentsRegistrationMessage:
      'If you have any questions regarding registration fees please contact us at',
    paymentsViewAll: 'Can I view All my Transactions, Dues, and Sponsorships ?',
    paymentsViewAllMessage: `Of course you can! In respect to your participation to our event we present to you below all the data about you, regarding payments. You can view which of your dues are sponsored, paid or pending. For any transaction that you completed by card you can always view and print your receipt with the Online Payment Information.`,
    paymentsRegistrationFeeMessage: `Your registration fee is calculated automatically based on the date and the data you have provided as your info. If you believe the registration fee is not suited for you please change your data`,
    abstractNotPublished: 'No abstracts found',
    selectMaxGuests: 'Select max guests',
    selectStarRank: 'Select star rank',
    session: 'Session',
    abstractsSubmissionNoStart: 'Abstract submission process is not open yet',
    noSubscriptionSet:
      'Registration for your group is not available yet, please contact us for further information',
    paid: 'Paid',
    search: 'Search',
    searchByTitleOrPublishedId: 'Search by Title or Published ID',
    subscriptionNotFound:
      'No available subscription was found, please contact us for more information',
    pending: 'Pending',
    sessions: 'Sessions',
    sessionMedia: 'Session Media',
    numberOfGuests: '# of Guests',
    status: 'Status',
    speakers: 'Speakers',
    speechMedia: 'Speech Media',
    eventMedia: 'Schedule',
    day: 'Day',
    noNewNotifications: 'No new notifications!',
    checkYourEmail: 'Check your email',
    sentRecoveryEmailAt: "We've sent a password recovery email at",
    noMailReceived: "Didn't receive an email?",
    sendEmailAgain: 'Click here to send a new email',
    dropFilesHere: 'Drop your files here or',
    saveChanges: 'Save changes',
    browse: 'Browse',
    noAbstractsFound: 'No Abstracts Found',
    abstractsSubmissionOver: 'The Abstract Submission Period Has Ended!',
    saveAsDraft: 'Save as draft',
    submitAbstract: 'Submit Abstract',
    submitDescription: 'Once submitted, the form cannot be altered.',
    paypalOption: 'Complete your payment via Paypal',
    creditCardOption:
      'Complete your payment via a Credit, Debit or Prepaid Card',
    bankTransferOption: 'Complete your payment via Bank Transfer',
    navigateOutOfSite:
      'You are about to open a new tab and navigate to a different site, are you sure?',
    and: 'and',
    more: 'more',
    chairpersons: 'Chairpersons',
    cancelRegistrationConfirmation:
      'Are you sure you want to cancel your registration?',
    close: 'Close',
    submitAbstractDescription:
      'You are about to submit your abstract. Do you want to proceed?',
    edit: 'Edit',
    authors: 'Authors',
    correspondingAuthorText:
      'Corresponding author: Author to whom correspondence should be addressed.',
    mediaFiles: 'Media Files',
    id: 'ID',
    registeredAt: 'Registered at',
    save: 'Save',
    cancel: 'Cancel',
    transactionHistory: 'Transaction History',
    reset: 'Clear Filters',
    invoices: 'Invoices',
    submitPublishedFiles: 'Submit published files',
    from: 'from',
    to: 'To',
    readMore: 'Read more',
    cancelPurchase: 'Cancel Purchase',
    readLess: 'Read less',
    viewFile: 'View File',
    support:
      'If you experiencing any difficulties with your registration, contact us at',
    confirm: 'Confirm',
    pricePerNight: 'From',
    nights: 'nights',
    night: 'night',
    addNote: 'Add Note',
    free: 'Free',
    addNotePlaceholder: 'Please type any notes related with your reservation',
    completeDuesReservationMessage:
      'Please complete any pending dues regarding your registration in order to make a Hotel Reservation!',
    cancelReservationMessage:
      'Do not leave this page. Please proceed the payment in order to complete the reservation!',
    reservationAvailableMessage: 'Your reservation will be available for',
    minutes: 'minutes',
    cancelReservation: 'Cancel Reservation',
    abstractInfo:
      'Complete the form carefully and only submit when you are ready',
    agree: 'I agree on ',
    locateInMap: 'Locate in map',
    printBadge: 'Get Badge',
    printAppleWalletBadge: 'Get Apple Wallet Badge',
    printGoogleWalletBadge: 'Get Google Wallet Badge',
    chosenPlan: 'Chosen plan',
    watchTime: 'Watch time',
    analysis: 'Analysis',
    totalPoints: 'Total Points',
    points: 'Total Available Points',
    acquiredPoints: 'Acquired points',
    onSite: 'On site',
    seeRooms: 'See Rooms',
    noAvailableRooms: 'No available rooms',
    viewReservation: 'View your Reservation',
    alphaBankSafeMessage: `All payments made using the card are processed through the electronic payment platform of "Alpha e-Commerce" of Alpha Bank and uses TLS 1.2 encryption protocol encryption with 128-bit (Secure Sockets Layer - SSL). Encryption is a way of coding the information until it reaches its recipient, who will be able to decode it using the appropriate key.`,
    termsOfUse1: `You are solely responsible for protecting your own account password and other account information.`,
    termsOfUse2: ` You can't use BlueTree to steal presentations or other content, and you have to access all data through BlueTree or on a device officially supported by BlueTree.`,
    termsOfUse3: `Do not use BlueTree if you do not agree to the Terms of Use described here. Your use of BlueTree means you agree to these Terms of Use.`,
    termsOfUse4: `At any moment you can contact with our Support Team and be informed about how we handle your data or how you can delete them`,
    receipt: 'Receipt',
    downloadReceipt: 'Download the Receipt',
    transactionRefusedMessage1: `
                            YOUR CREDIT CARD IS NOT APPROVED
                            THE DEBIT OF YOUR CREDIT CARD IS NOT POSSIBLE
                            THE TRANSACTION WAS NOT COMPLETED

                            Typical reasons for processing interruption
              `,
    transactionRefusedMessage2: `
                            1. Wrong card details
                            2. Exceeding of your credit limit
                            3. Low quality of your internet connection
                            4. Unexpected interruption of your internet connection
                            5. Transaction Canceled`,
    transactionFailedMessage1: `
                            YOUR CREDIT CARD IS NOT APPROVED
                            THE DEBIT OF YOUR CREDIT CARD IS NOT POSSIBLE
                            THE TRANSACTION WAS NOT COMPLETED
                            Typical reasons for processing interruption
              `,
    transactionFailedMessage2: `
                            1. Wrong card details
                            2. Exceeding of your credit limit
                            3. Low quality of your internet connection
                            4. Unexpected interruption of your internet connection
                            5. Transaction Canceled`,

    bookmarks: 'Bookmarks',
    speech: 'Speech',
    resetYourPassword: 'Reset your password',
    backToLogin: 'Back to login',
    backToMyAbstract: 'Back to my Abstracts',
    backToProducts: 'Back to Products',
    passwordReset:
      'We will send you an email to change your password, are you sure?',
    noBookmarksFound: 'No Bookmarks Found!',
    no: 'No',
    found: 'Found',
    register: 'Register here',
    signupMessage: 'Not Registered? SIGN-UP HERE',
    sessionFiles: 'Session Files',
    passwordRequest: 'Reset Password',
    forgotMyPassword: 'Forgot my password',
    requestNewPassword: 'Request new password',
    setNewPassword: 'Set a new password',
    confirmPassword: 'Confirm new password',
    newPasswordRequirements:
      'Your new password needs to be different from previously used passwords.',
    forgotPasswordQuestion: 'Forgot your password?',
    forgotPasswordReassurance:
      'Do not worry, we will send you instructions to restore it.',
    signupMessage1:
      "Not Registered? Please type your email, click 'Submit' and you will receive an email with instructions",
    forgotPasswordMessage:
      "Forgot your password or Request a new one? Please type your email, click 'Submit' and you will receive an email with instructions",
    submitPassword: 'Submit New Password',
    or: 'or',
    paymentsNotPublished: 'No payment has been made yet ',
    loginMessage: 'Please Login',
    passwordRestored: 'Password has been restored',
    continueAfterPassReset:
      'Your password has been successfully restored. Click below to login.',
    expiredTokenMessage: `Expired or invalid token. Please request new 'reset password' email.`,
    rememberPasswordMessage: 'Remembered your password? Login here',
    ok: 'OK',
    login: 'Login',
    completedSuccessfully: 'Completed successfully!',
    submit: 'Submit',
    submitYourRegistration: 'Complete your Registration',
    validateYourRegistration: 'Validation of your registration data',
    bankTransfer: 'Bank Transfer',
    creditOrDebitCard: 'Credit or Debit Card',
    userPreviousInfo: `We have some of your information from previous events or one of our admins has registered you in our system. Please validate the information we have and provide any we are missing.`,
    userAdminRegistered: `We have some of your information from previous attempts to register or one of our admins has registered you in our system. Please validate the information we have and provide any we are missing.`,
    userDontRememberPassword: ` If you do not remember the password you had used please request a new one after your registration.`,
    userProvidePasswordMessage: ` If you provide a password along with the other data we need you will enable your access to our client panel where you can manage your account.`,
    goToLogin: 'Go to login page',
    sendVerification: 'Request Verification Email',
    alreadyRegistered: 'Already Registered?',
    loginHere: 'Login here',
    expiredTokenMessage1:
      'Expired or Invalid token. Please request new verification email.',
    followSteps:
      'Follow the steps bellow in order to Complete you Registration!',
    publicNotAllowed: 'You are not allowed to edit your account in this event',
    followStepsValidation:
      'Follow the steps bellow in order to Validate the information we have about you!',
    provideInfo: 'Provide some information we need about you',
    respectInfo: `* We think of your information with great respect. If any time in the future you would like us to delete any information you have provided us, you can always contact with our Congress Support Team and resolve any request you have.`,
    RegistrationCalculatedMessage: `Your registration fee is calculated automatically based on the date and the data you have provided as your info. If you believe the registration fee is not suited for you please change your data in the previous step or contact our support team. Below you can view the Registration Type Fee that suites you after you click the "Calculate Registration Fee".`,
    recalculateRegistrationMessage: `Click the button below in order to calculate or re-calculate Registration Fee`,
    submitRegistration:
      'Click the button below in order to Submit your Registration',
    requiredFields: '(*) Make sure you have filled all the required fields',
    notifications: 'Notifications',
    markAllAsRead: 'Mark all as read',
    share: 'Share',
    backToPublishedAbstracts: 'Back to published abstracts',
    backToHotels: 'Back to hotels',
    backToPayments: 'Back to payments',
    backToExhibition: 'Back to exhibition',
    submitYouRegistration: 'Submit your Registration',
    selectPaymentMethod: 'Please select a Payment Method',
    registrationAlmostCompleted: `Your registration is almost completed. Please select any additional items (if any) you would like to purchase along with your registration and select a payment method.`,
    noItemsFound: 'No items found!',
    calculateRegistrationFeeMessage: 'Please Calculate your Registration Fee!',
    calculateRegistrationFee: 'Calculate your Registration Fee',
    validate: 'Validate',
    productsMatchMethods: `It looks like not all of your selected products are available for this Payment Method! Please select a method that applies to all of the products you want to purchase or remove any product that does not match this payment method.`,
    redirectToSecure: 'Redirect Me to Secure Payment',
    generalInfo: 'Home',
    exhibition: 'Exhibition',
    accomodationsFound: 'Results found',
    results: 'Results',
    sponsors: 'Sponsors',
    ebag: 'E-bag',
    products: 'Products',
    schedule: 'Schedule',
    abstracts: 'Abstracts',
    abstractForm: 'Abstract Form',
    noSessionsFound: 'No Sessions Found!',
    accomodation: 'Accommodation',
    yourReservationWillExpireAt: 'Your reservation will expire at',
    payments: 'Payments',
    videos: 'Videos',
    myAccount: 'My Account',
    signupLogin: 'Sign Up/Login',
    abstractsNotPublished: 'Abstracts have not been published!',
    overallSchedule: 'Overall Schedule',
    selectDates: 'Please select accommodation dates to view availability',
    selectDatesButton: 'Select Dates',
    outOfDateRange: 'Out of date range',
    publishedAbstracts: 'Published Abstracts',
    abstractsPaywall:
      'Please complete your registration payment to view published abstracts.',
    myAbstracts: 'My Abstracts',
    allProducts: 'All Products',
    myProducts: 'My Products',
    schedulePerDate: 'Schedule Per Date',
    scheduleNotPublished: 'No Results!',
    speakerNotPublished: 'The Speakers are not published yet',
    emptyResults: 'No results found',
    name: 'Name',
    room: 'Room',
    files: 'Files',
    video: 'Video',
    allTypes: 'All Types',
    stream: 'Stream',
    addToAgenda: 'Reserve seat',
    addToCart: 'Add to Cart',
    participatingSpeeches: 'Participating Speeches',
    speeches: 'Speeches',
    myAgenda: 'My Agenda',
    perPage: 'per page',
    type: 'Type',
    createdAt: 'Created At',
    paymentMethod: 'Payment Method',
    price: 'Price',
    totalPrice: 'Total Price',
    pricePerItem: 'Price/Item',
    quantity: 'Quantity',
    transactionId: 'Transaction Id',
    way: 'Method',
    amount: 'Amount',
    startDate: 'Start Date',
    endDate: 'End Date',
    selectTopic: 'Select topic',
    selectType: 'Select type',
    period: 'Period',
    account: 'My Account',
    myPayments: 'My Payments',
    logout: 'Log Out',
    itemNotAvailable: 'This product is not available.',
    registrationCost: 'Registration Cost',
    additionalProductsCost: "Additional Products' cost",
    taxesIncluded: 'All taxes included',
    checkoutTextRegistration:
      'You may choose one of the available checkout methods to complete your registration.',
    contactUs: 'If you are in need of assistance you may contact us at ',
    contactText: 'Contact us',
    orAt: 'or at',
    youHaveChosen: 'You have chosen: ',
    noCommonPayment:
      'The products you have chosen have no common payment option. Change your selected products to continue.',
    fileLoadError: "Couldn't load file. Please try again later.",
    checkoutTextGeneral:
      'You may choose one of the available checkout methods to complete your payment.',
    online: 'Online',
    totalMinutes: 'Minutes for Certification',
    passwordFieldPlaceholder: 'Enter your password',
    confirmPasswordFieldPlaceholder: 'Re-enter your new password',
    new: 'New',
    usernameFieldPlaceholder: 'Enter your username',
    emailFieldPlaceholder: 'Enter your email',
    haveNoAccount: 'Register to this event',
    passwordsDoNoMatch: 'Error: Passwords do not match',
    capsLockOn: 'Warning: Caps Lock is on',
    passwordStrengthMessage:
      'Password must be at least 6 characters long, include at least one (1) number and at least one (1) uppercase letter.',
  },
  gr: {
    passwordStrengthMessage:
      'Ο κωδικός πρόσβασης πρέπει να είναι τουλάχιστον 6 χαρακτήρες, να περιλαμβάνει τουλάχιστον 2 αριθμούς και να περιέχει τουλάχιστον ένα (1) κεφαλαίο γράμμα.',
    abstractTitlePlaceholder: 'Πληκτρολογήστε τον τίτλο της εργασίας σας',
    abstractContentPlaceholder:
      'Πληκτρολογήστε το περιεχόμενο της εργασίας σας',
    abstractTopicsPlaceholder: 'Επιλέξτε το θέμα της εργασίας σας',
    abstractTypePlaceholder: 'Επιλέξτε τον τρόπο παρουσίασης της εργασίας σας',
    abstractInstitutesPlaceholder: 'Πληκτρολογήστε το όνομα ιδρύματος',
    abstractAuthorFirstNamePlaceholder: 'Πληκτρολογήστε το όνομα του συγγραφέα',
    abstractAuthorLastNamePlaceholder:
      'Πληκτρολογήστε το επίθετο του συγγραφέα',
    abstractAuthorEmailPlaceholder: 'Πληκτρολογήστε το email του συγγραφέα',
    abstractAuthorInstitutePlaceholder: 'Επιλέξτε το ιδρυμα του συγγραφέα',
    typePlaceholder: 'Πληκτρολογήστε...',
    selectPlaceholder: 'Επιλέξτε...',
    totalMinutes: 'Λεπτά για ολοκλήρωση',
    onSite: 'Επι τόπου',
    online: 'Σε σύνδεση',
    new: 'Νέος',
    passwordFieldPlaceholder: 'Συμπληρώστε τον κωδικό σας',
    usernameFieldPlaceholder: 'Συμπληρώστε το όνομά χρήστη σας',
    emailFieldPlaceholder: 'Συμπληρώστε τo email σας ',
    fileLoadError: 'Το αρχείο δεν βρέθηκε. Παρακαλούμε προσπαθήστε αργότερα.',
    unsupportedFileType: 'Μη υποστηριζόμενο αρχείο',
    pdfFile: 'PDF',
    videoFile: 'Βίντεο',
    imageFile: 'Εικόνα',
    pleaseFillRequiredFields:
      'Παρακαλούμε συμπληρώστε όλα τα απαραίτητα πεδία.',
    previous: 'Προηγούμενο',
    previousStep: 'Προηγούμενο Βήμα',
    next: 'Επόμενο',
    nextStep: 'Επόμενο Βήμα',
    cancelRegistration: 'Ακύρωση εγγραφής',
    generalInfoRegistration: 'Γενικές Πληροφορίες',
    additionalProductsBenefits: 'Επιπλέον προϊόντα/παροχές',
    checkOutPayment: 'Ολοκλήρωση',
    noCommonPayment:
      'Τα προϊόντα που επιλέξατε δεν έχουν κοινό τρόπο πληρωμής. Αλλάξτε τα προϊόντα για να προχωρήσετε.',
    youHaveChosen: 'Έχετε επιλέξει: ',
    orAt: 'ή στο',
    contactUs: 'Αν χρειάζεστε βοήθεια μπορείτε να επικοινωνήσετε μαζί μας στο ',
    contactText: 'Επικοινωνία',
    checkoutTextRegistration:
      'Μπορείτε να επιλέξετε έναν από τους διαθέσιμους τρόπους πληρωμής για να ολοκληρώσετε την εγγραφή σας.',
    checkoutTextGeneral:
      'Μπορείτε να επιλέξετε έναν από τους διαθέσιμους τρόπους πληρωμής για να ολοκληρώσετε την συνναλαγή σας.',
    taxesIncluded: 'Με Φ.Π.Α.',
    additionalProductsCost: 'Κόστος επιπλέων προϊόντων',
    registrationCost: 'Κόστος Εγγραφής',
    itemNotAvailable: 'Το προϊόν δεν είναι διαθέσιμο.',
    passwordRequest: 'Ζητήστε νέο κωδικό',
    additionalProducts:
      'Μπορείτε να επιλέξετε κάποια από τις επιπλέον παροχές/προϊόντα ώστε να κατοχυρώσετε τη θέση σας.',
    exclusiveInformation:
      'Τα στοιχεία που παρέχετε χρησιμοποιούνται αποκλειστικά για τη διοργάνωση του συνεδρίου. Για περισσότερες πληροφορίες μπορείτε να δείτε τους όρους χρήσης στο τελευταίο βήμα ή να επικοινωνήσετε μαζί μας.',
    searchByTitle: 'Τίτλος',
    selectVenue: 'Αίθουσα',
    selectSpeaker: 'Ομιλητής',
    question: 'Ερώτηση',
    and: 'και',
    reset: 'Καθαρισμός φίλτρων',
    videoFormText:
      'Πληκτρολογήστε το όνομά σας και την ερώτηση προς τον ομιλητή',
    questionsText:
      'Κάντε κλικ εδώ εάν θέλετε να κάνετε κάποια ερώτηση μέσω e-mail',
    questionsTextError: 'Εναλλακτικά στείλτε email με την ερώτηση στο',
    deleteAbstract: 'Προκειται να διαγράψετε αυτή την εργασία.',
    deleteDescription: 'Η συγκεκριμένη ενέργεια είναι μη αντιστρέψιμη.',
    ondemandTitle: 'ON DEMAND ΜΕΤΑΔΟΣΗ ΣΥΝΕΔΡΙΟΥ',
    onDropRejected:
      'Το αρχείο που δοκιμάσατε να μεταφορτώσετε δεν συνάδει με τους αποδεκτούς τύπους αρχείων. Οι αποδεκτοί τύποι αρχείων είναι',
    fileInfoDimentions: 'Διαστάσεις',
    maxFileSize: 'Μέγιστος Όγκος αρχείου',
    uploadFiles: 'Μεταφόρτωση Αρχείων',
    filesLimitReached:
      'Το ανώτατο όριο πλήθους αρχείων έχει ξεπεραστεί. Παρακαλούμε διαγράψτε ένα αρχείο και έπειτα μεταφορτώστε κάποιο άλλο στην θέση του. Το μέγιστο πλήθος αρχείων είναι',
    addNotePlaceholder:
      'Παρακαλώ πληκτρολογήστε σημειώσεις που σχετίζονται με την κράτησή σας',
    loader: 'Φόρτωση',
    resetMapCenter: 'Επανεστίαση Χάρτη στο Κέντρο',
    rowsPerPage: 'Εγγραφές ανά σελίδα',
    typeMessage: 'Πληκτρολογήστε το μήνυμα σας',
    of: 'από',
    select: 'Επιλογή',
    checkin: 'Check-in',
    checkout: 'Check-out',
    firstName: 'Όνομα',
    lastName: 'Επώνυμο',
    institute: 'Ίδρυμα',
    allTypes: 'Όλοι οι Τύποι',
    institutes: 'Ιδρύματα',
    noAvailableRooms: 'Δεν υπάρχουν διαθέσιμα δωμάτια',
    addInstitutes: 'Προσθήκη Ιδρυμάτος',
    email: 'Email',
    addAuthors: 'Προσθήκη Συγγραφέα',
    abstractTitle: 'Τίτλος Εργασίας',
    abstractContent: 'Περίληψη Εργασίας',
    main: 'Κύριος',
    mainAuthor: '* Κύριος Συγγραφέας (Corresponding/Main Author)',
    author: 'Συγγραφέας',
    fileName:
      'Το όνομα του αρχείου συνίσταται να είναι σε λατινικούς χαρακτήρες και να μην περιέχει κενά, ή άλλους ειδικούς χαρακτήρες (*, /, ?, +, κ.α.).',
    createAbstract: 'Δημιουργία Νέας Εργασίας',
    createNew: 'Δημιουργία',
    hi: '',
    registrationNotCompleted: 'η εγγραφή σας δεν έχει ολοκληρωθεί!',
    abstractDuesMessage:
      'Παρακαλούμε ολοκληρώστε τις εκκρεμείς οφειλές σας σχετικά με την εγγραφή ωστε να καταθέσετε εργασία!',
    videoDuesMessage:
      'Παρακαλούμε ολοκληρώστε τις εκκρεμείς οφειλές σας σχετικά με την εγγραφή ωστε να παρακολουθήσετε ένα Βίντεο!',

    goToPayments: 'Μετάβαση στις Πληρωμές',
    viewReservation: 'Δείτε την κράτησή σας',
    selectMaxGuests: 'Επιλέξτε όριο ατόμων',
    selectStarRank: 'Επιλέξτε αστέρια',
    publicNotAllowed:
      'Η λειτουργία επεξεργασίας λογαριασμού, δεν υποστηρίζεται σε αυτόν τον τύπο συνεδρίου ',
    invalidEmail: 'Μη έγκυρο Email',
    extraFeatures: 'Περισσότερα',
    topics: 'Θεματολογία',
    types: 'Τρόπος Παρουσίασης',
    draft: 'Πρόχειρο',
    newAbstractStatus: 'Σύνταξη Νέας Εργασίας',
    approved: 'Εγκρίθηκε',
    rejected: 'Απορρίφθηκε',
    awaiting_resubmission: 'Αναμονή εκ νέου υποβολής',
    noAbstractSchema: 'Δεν βρέθηκε σχήμα Υποβολής Εργασίας!',
    noProducts: 'Δεν βρέθηκαν προϊόντα!',
    noMyProducts: 'Δεν έχετε αγοράσει κανένα προϊόν!',
    submitted: 'Υποβεβλημένο',
    under_review: 'Υπο Εξέταση',
    abstractSubmitted:
      'Αυτή η εργασία έχει υποβληθεί, καμία επεξεργασία δεν επιτρέπεται.',
    yourMessage: 'Το μήνυμά σας',
    typeYourName: 'Πληκτρολογήστε το όνομά σας',
    mailDuplicateText: 'Θα λάβετε αντίγραφο του μυνήματος στο email σας.',
    judgeComments: 'Σχόλια Κριτών',
    submissionRules: 'Οδηγίες Υποβολής',
    starts: 'Έναρξη',
    ends: 'Λήξη',
    contactInformation: 'Πληροφορίες Επικοινωνίας',
    phoneNumber: 'Τηλέφωνο',
    otherInfo: '',
    addNote: 'Προσθήκη σημειώσεων',
    dates: 'Ημερομηνίες',
    facilities: 'Εγκαταστάσεις',
    description: 'Περιγραφή',
    suitableFor: 'Κατάλληλο για',
    notAvailable: 'Δεν υπάρχει διαθεσιμότητα',
    available: 'Διαθέσιμο',
    saveChanges: 'Αποθήκευση αλλαγών',
    sheduleDownloadInfo: 'Για να κατεβάσετε το πρόγραμμα',
    clickHere: 'πατήστε εδώ',
    clickHereCaps: 'Πατήστε Εδώ',
    here: 'εδώ',
    results: 'Αποτελέσματα',
    speakerInfo: 'Δεν υπάρχουν διαθέσιμες πληροφορίες για ομιλητές',
    orderTotal: 'Σύνολο Παραγγελίας',
    concurrentLogin1: 'Ο λογαριασμός σας συνδέθηκε από άλλη συσκευή',
    concurrentLogin2: 'Έχετε αποσυνδεθεί από τον λογαριασμό σας.',
    close: 'Κλείσιμο',
    cancelRegistrationConfirmation:
      'Είστε σίγουροι πως θέλετε να ακυρώσετε την εγγραφή?',
    importantNotes: 'Παρακαλούμε προσθέστε οποιαδήποτε σημαντική πληροφορία',
    completedReservation: 'Η κράτηση σας έχει ολοκληρωθεί',
    proceedToPurchase: 'Προχωρήστε σε αγορά',
    proceedPaymentReservation:
      'Προχωρήστε στην πληρωμή για να ολοκληρώσετε την κράτησή σας!',
    goToPaymentsCompletePayment:
      'Πηγένετε στις πληρωμές για να ολοκληρώσετε την πληρωμή σας!',
    overview: 'Επισκόπηση',
    view: 'Επισκόπηση',
    myCart: 'Το Καλάθι μου',
    emptyCart: 'Αδειάστε το καλάθι',
    startingFrom: 'διαθέσιμο στα',
    purchasedAt: 'αγοράστηκε στα',
    confirmedPayment: 'Εξωφλήθη',
    checkAvailabilityMessage:
      'Επιλέξτε ημερομηνίες για να ελέγξετε την διαθεσιμότητα',
    date: 'Ημερομηνία',
    selectRoom: 'Επιλέξτε Δωμάτιο',
    reservedRoom: 'Δεσμευμένο Δωμάτιο',
    bookNowFor: 'Κάντε κράτηση τώρα με',
    bookNow: 'Κάντε κράτηση τώρα',
    product: 'Προϊόν',
    more: 'άλλα',
    haveHotelReservation: 'Έχετε μια κράτηση',
    hotels: 'Διαμονή',
    markAllAsRead: 'Σημείωση ως αναγνωσμένα',
    completeRegistration: 'Ολοκλήρωση Εγγραφής',
    validateRegistration: 'Επαλήθευση Εγγραφής',
    completeRegistrationMessage:
      "Για να ολοκληρώσετε την εγγραφή σας, παρακαλούμε συμφωνήστε με τους όρους χρήσης και επιλέξτε 'Ολοκλήρωση εγγραφής'.",
    validateRegistrationMessage:
      "Για να επαληθεύσετε τα στοιχεία της εγγραφή σας, παρακαλούμε συμφωνήστε με τους όρους χρήσης και επιλέξτε 'Επαλήθευση στοιχείων'.",
    country: 'Χώρα',
    state: 'Νομός',
    city: 'Πόλη',
    outOfDateRange: 'Εκτός εύρος αναζήτησης ημερομηνιών',
    zipCode: 'Ταχυδρομικός Κώδ.',
    address: 'Διεύθυνση',
    choosePay: 'Επιλέξτε τρόπο πληρωμής',
    termsOfUse: ' Όρους Χρήσης',
    support:
      'Εαν αντιμετωπίζετε κάποιο πρόβλημα με την εγγραφή σας, επικοινωνήστε μαζί μας στο',
    cancellationPolicy: 'Πολιτική Ακύρωσης Συναλλαγής',
    agreeTermsOfService: 'Συμφωνώ με τους όρους χρήσης',
    transactionCompleted: 'Η συναλλαγή ολοκληρώθηκε επιτυχώς!',
    orderId: 'Κωδικός Παραγγελίας',
    termsOfService: 'Όρους Χρήσης ',
    paymentReference: 'Κωδικός Αναφοράς Πληρωμής',
    transactionRefused:
      'Η Συναλλαγή Απορρίφθηκε!\nΠαρακαλούμε επαναλάβετε την διαδικασία',
    transactionFailed:
      'Η Συναλλαγή Δεν Ολοκληρώθηκε!\nΠαρακαλούμε επαναλάβετε την διαδικασία!',
    pleaseAgreeToTerms:
      'Για να συνεχίσετε, πρέπει να συμφωνήσετε με τους όρους χρήσης.',
    checkValidFieldErrorMsg: 'Παρακαλώ συμπληρώστε όλα τα υποχρεωτικά πεδία',
    duesMessage:
      'Έχετε εκκρεμείς οφειλές. Παρακαλούμε επιλέξτε ένα τρόπο πληρωμής ωστε να ολοκληρώσετε οποιαδήποτε συναλλαγή χρειάζεται.',
    paymentsCard:
      'Θέλετε να ολοκληρώσετε τις εκκρεμείς οφειλές σας με Πιστωτική ή Χρεωστική Κάρτα;',
    selectProducts:
      'Παρακαλούμε επιλέξτε ένα ή περισσότερα προϊόντα για να συνεχίσετε με την πληρωμή.',
    contactUsMessage:
      'Εάν έχετε οποιαδήποτε απορία σχετικά με τις πληρωμές παρακαλούμε επικοινωνήστε μαζί μας στο ',
    noPendingPayments: 'Δεν έχετε εκκρεμείς πληρωμές!',
    proceedPayment: 'Μετάβαση στη σελίδα πληρωμής',
    paymentsBank:
      'Θέλετε να ολοκληρώσετε τις εκκρεμείς οφειλές σας με Τραπεζική Κατάθεση ;',
    bankInfo: 'Πληροφορίες Τράπεζας',
    iban: 'IBAN',
    requiredFields:
      '(*) Βεβαιωθείτε ότι έχετε συμπληρώσει όλα τα απαιτούμενα πεδία',
    paymentOptions: 'Τρόποι Πληρωμής',
    requiredField: 'Αυτό το πεδίο είναι υποχρεωτικό!',
    register: 'Εγγραφείτε εδώ',
    bankMessage:
      "Ολες οι πληρωμές με την μέθοδο 'Τραπεζική Κατάθεση' θα τεθούν ολοκληρωμένες όταν η κατάθεση ολοκληρωθεί και επικυρωθεί από την Ομάδα Διαχείρισης του Συνεδρίου.",
    paymentsContactUsMessage:
      'Εάν έχετε απορίες σχετικά με τις πληρωμές σας, παρακαλούμε επικοινωνήστε μαζί μας στο',
    paymentsRegistrationFee:
      'Πώς υπολογίζεται το κόστος εγγραφής μου και πώς μπορώ να το αλλάξω;',
    paymentsRegistrationFeeMessage: `Το κόστος εγγραφής σας υπολογίζεται αυτόματα βάσει της ημερομηνίας και των πληροφοριών που μας έχετε δώσει. Εάν θεωρείται πως ο τύπος της εγγραφής δεν σας ικανοποιεί παρακαλούμε αλλάξτε τις πληροφορίες που μας έχετε δώσει`,
    paymentsRegistrationFeeViewMessage: `Παρακάτω μπορείτε να δείτε όλους τους διαθέσιμους τύπους εγγραφής. Αυτός που σας αντιστοιχεί θα είναι επιλεγμένος.`,
    contactUsPaymentsRegistrationMessage:
      'Εάν έχετε απορίες σχετικά με το κόστος εγγραφής σας, παρακαλούμε επικοινωνήστε μαζί μας στο',
    paymentsViewAll:
      'Μπορώ να δω όλες τις Συναλλαγές, Οφειλές, και Χορηγίες που με αφορούν;',
    paymentsViewAllMessage: `Ασφαλώς και μπορείτε! Με σεβασμό στην συμμετοχή σας στο συνέδριο μας σας παραθέτουμε όλα τα δεδομένα που έχουμε σχετικά με τις πληρωμές σας. Μπορείτε να δείτε ποιες από τις οφειλές σας είναι χορηγημένες, ολοκληρωμένες με συναλλαγή ή εκκρεμείς. Για οποιαδήποτε συναλλαγή που ολοκληρώνετε με Κάρτα θα υπάρχει διαθέσιμη πάντα η δυνατότητα εκτύπωσης του Αποδεικτικού Παραστατικού Ηλεκτρονικής Συναλλαγής.`,
    paymentsRegisrationFeeMessage: `Το κόστος εγγραφής σας υπολογίζεται αυτόματα βάσει της ημερομηνίας και των πληροφοριών που μας έχετε δώσει. Εάν θεωρείτε πως ο τύπος της εγγραφής δεν σας ικανοποιεί παρακαλούμε αλλάξτε τις πληροφορίες που μας έχετε δώσει`,
    paypalOption: 'Δίνεται δυνατότητα πληρωμής μέσω PayPal.',
    title: 'Τίτλος',
    share: 'Κοινοποίηση',
    copyLink: 'Αντιγραφή συνδέσμου',
    shareAbstract: 'Κοινοποίηση Εργασίας',
    requestNewPassword: 'Ζητήστε νέο κωδικό',
    forgotMyPassword: 'Ξέχασα τον κωδικό μου',
    totalCost: 'Συνολικό Κόστος',
    fileSize: 'Μέγεθος αρχείου',
    backToPublishedAbstracts: 'Πίσω στις δημοσιευμένες εργασίες',
    backToHotels: 'Πίσω στα ξενοδοχεία',
    backToPayments: 'Πίσω στις πληρωμές',
    backToExhibition: 'Πίσω στην έκθεση',
    abstractsSubmissionNoStart:
      'Η διαδικασία υποβολής εργασιών, δεν έχει ξεκινήσει ακόμη',
    uploadPublishedThumbnail:
      'Μεταφορτώστε εικόνα thumbnail για την εργασία σας',
    uploadPublishedFiles:
      'Μεταφορτώστε το αρχείο προς δημοσίευση για την εργασία σας',
    creditCardOption:
      'Δίνεται δυνατότητα πληρωμής με πιστωτική, χρεωστική ή προπληρωμένη κάρτα.',
    bankTransferOption: ' Δίνεται δυνατότητα πληρωμής με τραπεζική κατάθεση',
    abstractNotPublished: 'Δεν βρέθηκαν εργασίες',
    session: 'Συνεδρία',
    sessions: 'Συνεδρίες',
    sessionMedia: 'Αρχεία Συνεδρίας ',
    status: 'Κατάσταση',
    speakers: 'Ομιλητές',
    mediaFiles: 'Αρχεία',
    speechMedia: 'Αρχεία Ομιλίας',
    eventMedia: 'Πρόγραμμα',
    viewAll: 'Όλες οι Εργασίες',
    day: 'Ημέρα',
    pending: 'Σε εκκρεμότητα',
    noNewNotifications: 'Δεν έχετε νέες ενημερώσεις!',
    abstractsPaywall:
      'Παρακαλώ ολοκληρώστε την πληρωμή εγγραφής σας για να δείτε τις δημοσιευμένες εργασίες.',
    notifications: 'Ειδοποιήσεις',
    dropFilesHere: 'Σύρτε τα αρχεία σας εδώ ή',
    browse: 'Mεταφορτώσετε',
    noAbstractsFound: 'Δεν Βρέθηκαν Υποβεβλημένες Εργασίες',
    abstractsSubmissionOver: 'H Περίοδος Υποβολής Εργασιών Έχει Παρέλθει',
    saveAsDraft: 'Αποθήκευση ώς Πρόχειρο',
    submitAbstract: 'Υποβολή Εργασίας',
    update: 'Ενημέρωση',
    remove: 'Αφαίρεση',
    payoff: 'Εξόφληση',
    submitDescription:
      'Μετά την υποβολή δεν μπορείτε να επεξεργαστείτε την εργασία σας ξανα',
    submitAbstractDescription:
      'Πρόκειται να υποβάλετε οριστικά την εργασία σας. Θέλετε να συνεχίσετε;',
    edit: 'Έπεξεργασία',
    id: 'Κωδικός',
    registeredAt: 'Εγγράφηκε στις',
    chairpersons: 'Πρόεδροι',
    noPaymentsHistory: 'Δεν υπάρχουν ολοκληρωμένες πληρωμές',
    save: 'Αποθήκευση',
    cancel: 'Ακύρωση',
    transactionHistory: 'Ιστορικό Συναλλαγών',
    invoices: 'Τιμολόγια',
    pendingPayments: 'Εκκρεμείς Οφειλές',
    perPage: 'ανά σελίδα',
    waitUntilFinish:
      'Επεξεργαζόμαστε το αίτημά σας. Η διαδικαδία αυτή μπορεί να διαρκέσει μερικά λεπτά. Παρακαλούμε μην κλείσετε την σελίδα.',
    from: 'από',
    to: 'Εώς',
    stars: 'Αστέρια',
    accomodationsFound: 'Αριθμός αποτελεσμάτων',
    nights: 'νύχτες',
    price: 'Τιμή',
    night: 'νύχτα',
    completeDuesReservationMessage:
      'Παρακαλούμε ολοκληρώσετε τις εκκρεμείς οφειλές σας σχετικά με την εγγραφή για να κάνετε μια Κράτηση Ξενοδοχείου!',
    reservationAvailableMessage: 'Η κράτησή σας θα είναι διαθέσιμη για',
    minutes: 'λεπτά',
    cancelReservation: 'Ακύρωση Κράτησης',
    complete: 'ΟΛΟΚΛΗΡΩΣΗ',
    applyFilters: 'Εφαρμογή Φίλτρων',
    disclaimerPolicy: 'Αποποίηση Ευθυνών',
    locateInMap: 'Εντοπισμός στον Χάρτη',
    seeRooms: 'Δείτε Δωμάτια',
    alphaBankSafeMessage: `Όλες οι πληρωμές που πραγματοποιούνται με χρήση κάρτας διεκπεραιώνονται μέσω της πλατφόρμας ηλεκτρονικών πληρωμών 'Alpha e-Commerce' της Alpha Bank και χρησιμοποιεί κρυπτογράφηση TLS 1.2 με πρωτόκολλο κρυπτογράφησης 128-bit (Secure Sockets Layer - SSL). Η κρυπτογράφηση είναι ένας τρόπος κωδικοποίησης της πληροφορίας μέχρι αυτή να φτάσει στον ορισμένο αποδέκτη της, ο οποίος θα μπορέσει να την αποκωδικοποιήσει με χρήση του κατάλληλου κλειδιού.`,
    termsOfUse1: `Είστε αποκλειστικά υπεύθυνος για την προστασία του κωδικού χρήσης σας και λοιπων πληροφοριών του λογαριασμού σας`,
    termsOfUse2: ` Δεν μπορείτε να χρησιμοποιήσετε την πλατφόρμα BlueTree με σκοπό να αποσπάσετε δεδομένα παρουσιάσεων και λοιπού περιεχομένου. Έχετε πρόσβαση σε όλα τα δεδομένα των συνεδρίων μέσω της BlueTree ή οποιασδήποτε συσκευή υποστηρίζει η πλατφόρμα`,
    termsOfUse3: `Μην χρησιμοποιήσετε την BlueTree εφόσον δεν συμφωνείτε με τους όρους χρήσης που περιγράφονται εδώ. Με την χρήση σας συμφωνείτε στους όρους χρήσης.`,
    termsOfUse4: `Οποιαδήποτε στιγμή θελετε μπορείτε να επικοινωνήσετε με την Ομάδα Υποστήριξής μας και να ενημερωθείτε σχετικά με την τήρηση των δεδομένων σας και πως μπορείτε να τα διαγράψετε.`,
    downloadReceipt: 'Κατεβάστε το Αποδεικτικό Συναλλαγής',
    receipt: 'Αποδεικτικό Συναλλαγής',
    bookmarks: 'Σελιδοδείκτες',
    noBookmarksFound: 'Δεν Βρέθηκαν Σελιδοδείκτες',
    sessionFiles: 'Αρχεία συνεδρίας',
    pricePerNight: 'Από',
    numberOfGuests: '# Επισκεπτών',
    download: 'Κατεβάστε',
    viewFile: 'Προβολή αρχείου',
    userNameWarning:
      'Προσοχή: Αυτό το όνομα θα εμφανίζεται στο πιστοποιητικό σας.',
    readMore: 'Δείτε περισσότερα',
    cancelPurchase: 'Ακύρωση Αγοράς',
    itemOverbooked: 'Υπέρβαση της διαθέσιμης ποσότητας!',
    itemSoldOut: 'Αυτό το προϊόν έχει εξαντληθεί!',
    editYourProfile: 'Επεξεργαστείτε το προφίλ σας',
    numberOfProductsInCart: 'Αριθμός προϊόντων στο καλάθι: ',
    maxQuantityReached: 'Έχετε φτάσει το μέγιστο όριο ποσότητας',
    readLess: 'Δείτε λιγότερα',
    correspondingAuthorText:
      'Ανταποκριτής: Συγγραφέας στον οποίο πρέπει να απευθύνεται η επικοινωνία.',
    payYourRegistration:
      'Παρακαλώ ολοκληρώστε εναπομοίναντες πληρωμές για την εγγραφή σας',
    alreadyWatching:
      'Βρίσκεστε ήδη σε ένα δωμάτιο εκδηλώσεων. Μπορείτε να συνεχίσετε αυτό το βίντεο, αλλά ο χρόνος σας δεν θα προσμετρηθεί.',
    yourReservationWillExpireAt: 'Η κράτησή σας θα λήξει στις',
    selectDates:
      'Παρακαλώ επιλέξτε ημερομηνίες διαμονής για να δείτε διαθεσιμότητα.',
    selectDatesButton: 'Επιλέξτε ημερομηνίες',
    paymentTitle: 'Πραγματοποιήστε μια συναλλαγή',
    no: 'Δεν',
    found: 'Βρέθηκε',
    signupMessage: 'Δεν έχετε Εγγραφεί; ΕΓΓΡΑΦΕΙΤΕ ΕΔΩ',
    signupMessage1:
      "Δεν έχετε Εγγραφεί; Πληκτρολογήστε το email σας, επιλέξτε 'Υποβολή' και θα λάβετε email με τις οδηγίες",
    or: 'ή',
    verificationText:
      'Πατήστε το παρακάτω για να σας σταλεί e-mail επαλήθευσης για την εγγραφή σας',
    loginMessage: 'Παρακαλούμε κάντε ΕΙΣΟΔΟ',
    expiredTokenMessage: `Ο κωδικός τεκμηρίωσης έχει λήξει. Παρακαλούμε ζητήστε ένα καινούριο email 'Νέου Κωδικού'`,
    rememberPasswordMessage: 'Θυμηθήκατε τον κωδικό σας; Κάντε ΕΙΣΟΔΟ εδώ',
    greetingRegistration: 'Η εγγραφής σας βρίσκεται κοντά στην ολοκλήρωσή της.',
    abstractForm: 'Φόρμα κατάθεσης εργασιών',
    noMailReceived: 'Δεν λάβατε το email;',
    sendEmailAgain: 'Κάντε κλικ για εκ νέου αποστολή',
    submitPublishedFiles: 'Υποβολή αρχείων δημοσίευσης',
    paymentsHistory: 'Ιστορικό πληρωμών',
    subscriptionNotFound:
      'Δεν βρέθηκε διαθέσιμος τύπος εγγραφής, παρακαλώ επικοινωνήστε μαζί μας',
    greetingValidation:
      'Η επαλήθευση των στοιχείων της εγγραφής σας βρίσκεται μερικά βήματα μακριά!',
    ok: 'OK',
    login: 'Είσοδος',
    abstractInfo:
      'Συμπληρώστε την φόρμα προσεκτικά και υποβάλλετέ την μόνο όταν είστε έτοιμοι',
    scheduleNotPublished: 'Δεν βρέθηκαν αποτελέσματα!',
    paymentsNotPublished: 'Δεν έχετε πραγματοποιήσει πληρωμές',
    emptyResults: 'Δεν βρέθηκαν αποτελέσματα',
    speakerNotPublished: 'Οι Ομιλητές δεν έχουν δημοσιευθεί ακόμα',
    completedSuccessfully: 'Ολοκληρώθηκε Επιτυχώς!',
    submit: 'Υποβολή',
    passwordReset:
      'Θα σας αποσταλεί email για να αλλάξετε τον κωδικό σας, είστε σίγουροι ?',
    bankTransfer: 'Τραπεζική Κατάθεση',
    creditOrDebitCard: 'Χρεωστική ή Πιστωτική Κάρτα',
    userPreviousInfo: `Έχουμε κάποιες πληροφορίες σας από προηγούμενα συνέδρια ή ένας από τους διαχειριστές μας σας έχει καταχωρήσει στο σύστημά μας. Παρακαλούμε επικυρώστε τις πληροφορίες που έχουμε και συμπληρώστε όσες μας λείπουν.`,
    userAdminRegistered: `Έχουμε τα στοιχεία σας από προηγούμενες προσπάθειες εγγραφής ή ένας απο τους διαχειριστές μας σας έχει εγγράψει στο σύστημά μας. Παρακαλούμε επικυρώστε τις πληροφορίες που έχουμε και παρέχετέ μας όσες μας λείπουν.`,
    registration: 'Εγγραφή',
    userDontRememberPassword: ` Εάν δεν θυμάστε τον κωδικό που είχατε χρησιμοποιήσει σε προηγούμενα συνέδρια ή δεν είχατε ποτέ κωδικό παρακαλούμε ζητήστε έναν μετά την ολοκλήρωση της εγγραφής σας.`,
    userProvidePasswordMessage: ` Δημιουργήστε τον επιθυμητό κωδικό στο αντίστοιχο πεδίο, "Κωδικός (Password)", για να μπορείτε να έχετε πρόσβαση και έλεγχο του λογαριασμού σας, μέσω της ιστοσελίδας μας.`,
    goToLogin: 'Μεταβείτε στην σελίδα Εισόδου',
    sendVerification: 'Ζητήστε Email Επαλήθευσης',
    alreadyRegistered: 'Είστε ήδη εγγεγραμένος;',
    loginHere: 'Κάντε Είσοδο εδώ',
    noSessionsFound: 'Δεν Βρέθηκαν Συνεδρίες!',
    speech: 'Ομιλία',
    forgotPasswordQuestion: 'Ξεχάσατε τον κωδικό;',
    newPasswordRequirements:
      'Ο νέος κωδικός σας πρέπει να είναι διαφορετικός από τον κωδικό που χρησιμοποιήσατε προηγουμένως.',
    passwordRestored: 'Ο κωδικός επαναφέρθηκε',
    continueAfterPassReset:
      'Ο κωδικός σας επαναφέρθηκε με επιτυχία. Κάντε κλικ παρακάτω για να εισέλθετε.',
    agree: 'Συμφωνώ με τους παρακάτω',
    resetYourPassword: 'Επαναφέρετε τον κωδικό',
    confirmPasswordFieldPlaceholder: 'Ξαναγράψτε τον νέο κωδικό',
    confirmPassword: 'Επιβεβαίωση νέου Κωδικού',
    backToLogin: 'Πίσω στην είσοδο',
    backToMyAbstract: 'Πίσω στις εργασίες μου',
    backToProducts: 'Πίσω στα προϊόντα',
    forgotPasswordReassurance:
      'Μην ανησυχείτε, θα σας στείλουμε οδηγίες επαναφοράς.',
    expiredTokenMessage1:
      'Ο κωδικός τεκμηρίωσης έχει λήξει. Παρακαλούμε ζητήστε ένα καινούριο Email επαλήθευσης.',
    search: 'Αναζήτηση',
    searchByTitleOrPublishedId: 'Αναζήτηση με Τίτλο ή Κωδικό δημοσίευσης',
    selectType: 'Επιλέξτε κατηγορία',
    selectTopic: 'Επιλέξτε θέμα',
    followSteps:
      'Ακολουθήστε τα παρακάτω βήματα για να Ολοκληρώσετε την Εγγραφή σας!',
    sentRecoveryEmailAt:
      'Στείλαμε έναν σύνδεσμο επαναφοράς κωδικού πρόσβασης στο',
    followStepsValidation:
      'Ακολουθήστε τα παρακάτω βήματα για να επαληθεύσετε τα στοιχεία που έχουμε για εσάς!',
    provideInfo: 'Συμπληρώστε τις πληροφορίες που χρειαζόμαστε από εσάς',
    respectInfo: `* Φιλοξενούμε τα δεδομένα σας με μεγάλο σεβασμό. Εάν οποιαδήποτε στιγμή θα θέλατε να διαγράψουμε οποιαδήποτε πληροφορία σχετικά με εσάς, μπορείτε πάντα να επικοινωνήσετε με την Ομάδα Υποστήριξής του Συνεδρίου και να ξεδιαλύνετε οποιοδήποτε αίτημά σας.`,
    RegistrationCalculatedMessage: `Το κόστος εγγραφής σας υπολογίζεται αυτόματα βάσει της ημερομηνίας και των πληροφοριών που μας έχετε δώσει. Εάν θεωρείται πως ο τύπος της εγγραφής δεν σας ικανοποιεί παρακαλούμε αλλάξτε τις πληροφορίες που μας έχετε δώσει στο προηγούμενο βήμα ή επικοινωνήσετε με την Ομάδα Υποστήριξής Συνεδρίου. Παρακάτω μπορείτε να δείτε τον Τύπο Εγγραφής που σας αντιστοιχεί αφού επιλέξετε το κουμπί "Υπολογισμός Κόστους Εγγραφής"`,
    recalculateRegistrationMessage: `Επιλέξτε το παρακάτω κουμπί για να υπολογίσετε ή ξανα-υπολογίσετε το Κόστος Εγγραφής σας`,
    submitRegistration:
      'Επιλέξτε το παρακάτω κουμπί για να Υποβάλετε την Εγγραφή σας',
    submitYouRegistration: 'Υποβάλετε την Εγγραφή σας',
    selectPaymentMethod: 'Παρακαλούμε επιλέξτε μια Μέθοδο Πληρωμής',
    registrationAlmostCompleted: `Η Εγγραφή σας έχει σχεδόν ολοκληρωθεί. Παρακαλούμε επιλέξτε επιπλέον "αντικείμενα" που θα θέλατε μαζί με την εγγραφή σας και επιλέξτε μια μέθοδο πληρωμής. `,
    noItemsFound: 'Δεν βρέθηκαν Αντικείμενα!',
    creditCard: 'Πιστωτική/Χρεωστική Κάρτα',

    calculateRegistrationFeeMessage:
      'Παρακαλούμε υπολογίστε το Κόστος Εγγραφής σας!',
    calculateRegistrationFee: 'Υπολογισμός Κόστους Εγγραφής',
    productsMatchMethods: `Φαίνεται πως τα επιλεγμένα αντικείμενα σας δεν είναι όλα διαθέσιμα με αυτην την Μέθοδο Πληρωμής. Παρακαλούμε επιλεξτε μια μέθοδο που ικανοποιεί όλα τα αντικείμενο που θέλετε να αγοράσετε ή αφαιρέστε οποιοδήποτε αντικείμενο δεν συμφωνεί με την μέθοδο πληρωμής.`,
    redirectToSecure: 'Μετάβαση σε Ασφαλές Περιβάλλον Πληρωμής',
    transactionRefusedMessage1: `Η ΣΥΝΑΛΛΑΓΗ ΜΕ ΤΗ ΠΙΣΤΩΤΙΚΗ ΣΑΣ ΚΑΡΤΑ ΔΕΝ ΕΙΝΑΙ ΕΦΙΚΤΗ
                            H ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΕΓΚΡΙΝΕΤΑΙ
                            Η ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΠΡΑΓΜΑΤΟΠΟΙΗΘΗΚΕ

                            Συνήθεις Λόγοι Μη πραγματοποίησης της συναλλαγής
              `,
    transactionRefusedMessage2: `
                            1. Λανθασμένη Καταχώριση των στοιχείων της Κάρτας σας
                            2. Υπέρβαση του Πιστωτικού Ορίου της Κάρτας σας
                            3. Xαμηλή Ποιότητα της Σύνδεσης σας με το Internet
                            4. Ξαφνική Διακοπή της Σύνδεσης σας με το Internet
                            5. Ακύρωση Συναλλαγής`,
    transactionFailedMessage1: `Η ΣΥΝΑΛΛΑΓΗ ΜΕ ΤΗ ΠΙΣΤΩΤΙΚΗ ΣΑΣ ΚΑΡΤΑ ΔΕΝ ΕΙΝΑΙ ΕΦΙΚΤΗ
                            H ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΕΓΚΡΙΝΕΤΑΙ
                            Η ΣΥΝΑΛΛΑΓΗ ΔΕΝ ΠΡΑΓΜΑΤΟΠΟΙΗΘΗΚΕ

                            Συνήθεις Λόγοι Μη πραγματοποίησης της συναλλαγής
              `,
    transactionFailedMessage2: `
                            1. Λανθασμένη Καταχώριση των στοιχείων της Κάρτας σας
                            2. Υπέρβαση του Πιστωτικού Ορίου της Κάρτας σας
                            3. Xαμηλή Ποιότητα της Σύνδεσης σας με το Internet
                            4. Ξαφνική Διακοπή της Σύνδεσης σας με το Internet
                            5. Ακύρωση Συναλλαγής`,
    generalInfo: 'Αρχική',
    sponsors: 'Χορηγοί',
    message: 'Μήνυμα',
    ebag: 'E-bag',
    free: 'Δωρεάν',
    products: 'Προϊόντα',
    noSubscriptionSet:
      'Η εγγραφή δεν είναι διαθέσιμη για εσάς αυτήν την στιγμή, παρακαλώ επικοινωνήστε με την γραμματεία',
    schedule: 'Πρόγραμμα',
    abstracts: 'Εργασίες',
    accomodation: 'Διαμονή',
    payments: 'Πληρωμές',
    printBadge: 'Λήψη Badge',
    printAppleWalletBadge: 'Λήψη Apple Wallet Badge',
    printGoogleWalletBadge: 'Λήψη Google Wallet Badge',
    chosenPlan: 'Επιλεγμένο πλάνο',
    watchTime: 'Λεπτά παρακολούθησης',
    totalPoints: 'Συνολικοί πόντοι',
    points: 'Συνολικοί Πόντοι παρακολούθησης',
    acquiredPoints: 'Επίκτητοι Πόντοι',
    analysis: 'Ανάλυση',
    videos: 'Βίντεο',
    myAccount: 'Ο Λογαριασμός μου',
    haveNoAccount: 'Εγγραφείτε σε αυτήν την εκδήλωση',
    signupLogin: 'Εγγραφή/Είσοδος',
    overallSchedule: 'Επισκόπηση Προγράμματος',
    publishedAbstracts: 'Δημοσιευμένες Εργασίες',
    myAbstracts: 'Οι Εργασίες μου',
    myProducts: 'Τα προϊόντα μου',
    allProducts: 'Όλα τα προϊόντα',
    myPayments: 'Οι Πληρωμές μου',
    schedulePerDate: 'Πρόγραμμα ανά Ημέρα',
    name: 'Όνομα',
    room: 'Αίθουσα',
    payDue: 'Πληρωμή με',
    paid: 'Πληρωμένη',
    files: 'Αρχεία',
    filters: 'ΦΙΛΤΡΑ',
    video: 'Βίντεο',
    stream: 'Stream',
    roomTypes: 'Τύποι δωματίων',
    setNewPassword: 'Ορίστε νέο κωδικό πρόσβασης',
    addToAgenda: 'Κράτηση θέσης',
    addToCart: 'Προσθήκη',
    submitYourRegistration: 'Ολοκληρώστε την Εγγραφή σας',
    validateYourRegistration: 'Επαλήθευση των στοιχείων της εγγραφής σας',
    participatingSpeeches: 'Συμμετέχουσες Ομιλίες',
    speeches: 'Ομιλίες',
    submitPassword: 'Υποβολή νέου κωδικού',
    forgotPasswordMessage:
      "Ξεχάσατε τον Κωδικό σας ή Επιθυμείτε Νέο; Πληκτρολογήστε το email σας, επιλέξτε 'Υποβολή' και θα λάβετε email με τις οδηγίες",
    myAgenda: 'Η Ατζέντα μου',
    authors: 'Συγγραφείς',
    type: 'Τύπος',
    abstractsNotPublished: 'Οι εργασίες δεν έχουν δημοσιευθεί!',
    createdAt: 'Ημερομηνία Δημιουργίας',
    transactionId: 'Κωδικός Συναλλαγής',
    way: 'Τρόπος',
    amount: 'Ποσό',
    startDate: '´Εναρξη',
    endDate: 'Λήξη',
    period: 'Περίοδος',
    checkYourEmail: 'Ελέγξτε το email σας',
    checkIn: 'Ημερομηνία άφιξης',
    checkOut: 'Ημερομηνία αναχώρησης',
    checkIn_checkOut: 'Ημερομηνία άφιξης - Ημερομηνία αναχώρησης',
    guests: 'Επισκέπτες',
    maxPricePerNight: 'Μέγιστο κόστος ανά νύχτα',
    hotelRank: 'Μικρότερη Κατηγορία Αστεριών',
    exhibition: 'Έκθεση',
    cancelReservationMessage:
      'Μην φύγετε από την σελίδα. Παρακαλούμε συνεχίστε με την πληρωμή για να ολοκληρώσετε την κράτησή σας!',
    account: 'Ο Λογαριασμός μου',
    logout: 'Αποσύνδεση',
    paymentMethod: 'Μέθοδος Πληρωμής',
    totalPrice: 'Σύνολο Τιμής',
    pricePerItem: 'Τιμή/Αντικείμενο',
    quantity: 'Ποσότητα',
    validate: 'Επαλήθευση',
    confirm: 'Επιβεβαίωση',
    categories: 'Κατηγορίες',
    navigateOutOfSite:
      'Θα ανοίξετε μια καινούρια καρτέλα και θα μεταφερθείτε σε έναν διαφορετικό ιστότοπο, είστε σίγουροι ότι θέλετε να συνεχίσετε;',
    passwordsDoNoMatch: 'Σφάλμα: Οι κωδικοί πρόσβασης δεν ταιριάζουν',
    capsLockOn: 'Προειδοποίηση: Το Caps Lock είναι ενεργοποιημένο',
  },
};
